import { gql } from '@apollo/client';

import type { Services, UserContext, UserContextInput, User } from 'src/types';

import { mapUserContextToUser } from './mappers';

type Params = UserContextInput;
type Response = User | null;

export const USER_CONTEXT = gql`
  query GetTalentSearchUserContext($jobId: ID) {
    talentSearchUserContext(input: { jobId: $jobId }) {
      id
      user {
        userId
        lastName
        firstName
        email
      }
      claims
      ats {
        id
        name
      }
      advertiser {
        id
        name
        accountNumber
        tags {
          testRecord
          testScope
          recordExpiry
          testBehaviours
        }
      }
      contracts {
        startDate
        endDate
        productType
      }
      productType
      contractBalance {
        ... on TalentSearchContractBalance {
          __typename
          initialBalance
          currentBalance
        }
        ... on TalentSearchUnavailableContractBalance {
          __typename
        }
        ... on ResponseError {
          __typename
          error
        }
      }
    }
  }
`;

const dataService: Services.DataService<Response, Params> = ({ query }) =>
  async function getUserContext(params) {
    const data = await query<{
      talentSearchUserContext: UserContext;
    }>({
      query: USER_CONTEXT,
      variables: { jobId: params.jobId },
      context: {
        disableAuthorisationHeaders: true,
        headers: {
          ...(params.jobId && { jobId: params.jobId }),
        },
      },
    });

    if (!data) {
      return null;
    }

    return data?.talentSearchUserContext
      ? mapUserContextToUser(data.talentSearchUserContext)
      : null;
  };

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default dataService;

import type { ConnectionType } from 'src/hooks/useConnections/types';

export const getAccessCvType = (
  connectionType: ConnectionType,
): string | undefined => {
  if (connectionType === 'accessProfile') {
    return 'access profile';
  }

  if (connectionType === 'accessProfileAndCv') {
    return 'access cv profile';
  }

  return undefined;
};

import { useMemo, useEffect, useRef, useCallback } from 'react';
import { useStore } from 'react-redux';

import { useACL } from '../useACL/useACL';
import useAppConfig from '../useAppConfig/useAppConfig';
import { useRoutes } from '../useRoutes';

import Context from './Context';
import type { MetricsProviderConfig, ContextType } from './types';

interface Props {
  providers: MetricsProviderConfig[];
  children: React.ReactNode;
}

function MetricsProvider({ providers, children }: Props) {
  const hasMounted = useRef<boolean>(false);
  const appConfig = useAppConfig();
  const { routes } = useRoutes();
  const { getProductConfig } = useACL();
  const getFeatureFlags = useCallback(
    () => getProductConfig().featureFlags,
    [getProductConfig],
  );

  const handlersRef = useRef<
    Record<string, ReturnType<MetricsProviderConfig['createHandler']>>
  >({});

  const { getState } = useStore();

  useEffect(
    //  Injects any required 3rd party scripts if configured
    () => {
      if (!hasMounted.current) {
        hasMounted.current = true;

        for (const { key, createScript, createHandler } of providers) {
          createScript?.(appConfig);
          handlersRef.current[key] = createHandler(
            appConfig,
            getFeatureFlags,
            routes,
          );
        }
      }
    },
    [providers, appConfig, getFeatureFlags, routes],
  );

  const value = useMemo(() => {
    //  Dispatches the event to all event providers in the configuration
    //  with the current snapshot of global state (e.g. store.getState())
    //  E.g. [tealium, browser, dataPlatform]
    const dispatchEvent: ContextType['dispatchEvent'] = (eventAction) => {
      try {
        const snapshot = getState();
        for (const { key, getValue } of eventAction) {
          const handler = handlersRef.current[key];
          if (handler) {
            handler.send(getValue(snapshot), snapshot);
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn(`metrics failed to send: ${err}`);
      }
    };
    return {
      dispatchEvent,
    };
  }, [getState]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default MetricsProvider;

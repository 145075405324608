import { useHirerAuth } from '@seek/hirer-auth-react';
import { useTranslations } from '@vocab/react';
import {
  Heading,
  Stack,
  Text,
  Box,
  Inline,
  IconCaution,
  List,
  Button,
  Strong,
} from 'braid-design-system';

import errorService from '../../../services/error';
import { type APIErrorCode, ErrorTypes } from '../../../types';

import translations from './.vocab';
import ErrorMessageText from './components/ErrorMessageText';
import mapContextToFriendlyMessage from './mapContextToFriendlyMessage';
import mapErrorTypeToMessage from './mapErrorTypeToMessage';

import * as styles from './ErrorMessage.css';

interface Props {
  errorType?: ErrorTypes;
  showErrorContext?: boolean;
  context?: {
    apiErrorCode?: APIErrorCode;
    opaqueErrorId?: string;
    jobId?: number;
    advertiserId?: number;
  };
  isFixedCenter: boolean;
}

function ErrorMessage({
  errorType = ErrorTypes.UNKNOWN,
  showErrorContext = false,
  context = {},
  isFixedCenter,
}: Props) {
  const { t } = useTranslations(translations);
  const { logout } = useHirerAuth();

  const { apiErrorCode, opaqueErrorId } = context;

  const code = opaqueErrorId
    ? opaqueErrorId
    : errorService.mapAPIErrorCodeToFriendlyCode(apiErrorCode);

  const { image, title, message, showLogoutAction, errorCode } =
    mapErrorTypeToMessage(errorType, {
      ...(apiErrorCode && { apiErrorCode }),
      code,
    });

  return (
    <Box
      display="flex"
      maxWidth="small"
      alignItems={isFixedCenter ? 'center' : undefined}
      className={isFixedCenter ? styles.containerCenter : styles.containerInset}
    >
      <Box
        boxShadow={isFixedCenter ? 'borderNeutralLight' : undefined}
        borderRadius="large"
        paddingX="large"
        paddingY="xlarge"
        background="body"
      >
        <Stack space="large">
          <Stack space="large" align="center">
            <Stack space="medium" align="center">
              <Box dangerouslySetInnerHTML={{ __html: image }} />
              <ErrorMessageText
                titleKey={title}
                translationKey={message}
                errorCode={errorCode}
              />
            </Stack>

            {showLogoutAction && (
              <Inline space="none" align="center">
                <Button
                  id="sign-out-button"
                  variant="ghost"
                  onClick={() => {
                    logout();
                  }}
                >
                  {t('SIGN_OUT')}
                </Button>
              </Inline>
            )}
          </Stack>

          {showErrorContext && (
            <Box
              id="error-context"
              background="cautionLight"
              padding="medium"
              boxShadow="borderCaution"
              data={{ testid: 'error-context' }}
            >
              <Stack space="large">
                <Inline alignY="center" space="small">
                  <Heading level="2">
                    <IconCaution tone="caution" />
                  </Heading>
                  <Text tone="caution" weight="strong">
                    {t('ADDITIONAL_ERROR_CONTEXT')}
                  </Text>
                </Inline>

                <List space="medium">
                  {Object.entries(context).map(([key, value]) => (
                    <Text key={key}>
                      {t(`${mapContextToFriendlyMessage(key)}`, {
                        key,
                        Strong: (children) => <Strong>{children}</Strong>,
                        value: String(value),
                      })}
                    </Text>
                  ))}
                </List>

                <Text size="small">{t('IMPERSONATING_DEBUG_INFO')}</Text>
              </Stack>
            </Box>
          )}
        </Stack>
      </Box>
    </Box>
  );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default ErrorMessage;

import locationService from '../../services/location';
import { type RootState, RouteKeys } from '../../types';

export const getActiveRouteSelector = ({ router }: RootState) => router;

export const getActiveRouteKeySelector = ({ router }: RootState) => router.key;

export const getActiveRoutePathnameSelector = ({ router }: RootState): string =>
  router.pathname;

export const getSceneStateSelector = ({ router }: RootState) =>
  'sceneState' in router ? router.sceneState : {};

export const getChannelFromParams = ({ router }: RootState): string =>
  router.params && router.params.channel ? router.params.channel : '';

export const getActiveJobIdSelector = ({ router }: RootState): number | null =>
  router ? router.jobId : null;

export const getAllParamsFromSearchSelector = ({ router }: RootState) =>
  locationService.queryStringService.parse(router.search) as Record<
    string,
    string
  >;

export const getParamFromSearchSelector = (
  { router }: RootState,
  param: string,
): string | null => {
  const search = locationService.queryStringService.parse(router.search);
  return search[param];
};

export const getSeedProfileIdFromQueryStringSelector = (
  state: RootState,
): string | null => getParamFromSearchSelector(state, 'seedProfileId') || null;

export const isValidRouteSelector = (state: RootState): boolean => {
  const hasAdvertiserId =
    'advertiserId' in getAllParamsFromSearchSelector(state);
  return (
    state.router.key !== '' &&
    ![RouteKeys.Root, RouteKeys.Error].includes(state.router.key) &&
    !hasAdvertiserId
  );
};

import { useLocation, matchPath } from 'react-router-dom';

import routes from '../../routes';
import queryStringService, {
  parsers,
} from '../../services/location/queryStringService';

const _routes = [...routes.unrestricted, ...routes.restricted];

/*
 * Allows us to use this hook from outside of a
 * <Route /> component. The hook is used in many places
 * which may or may not have context of react-router-dom
 */
function matchParams(pathname: string) {
  let params: Record<string, unknown> = {};
  for (const { path, exact } of _routes) {
    const _match = matchPath(pathname, { path, exact });
    if (_match) {
      params = _match.params;
      break;
    }
  }
  return params;
}

/**
 * Extracts an active jobId from either pathname params or search params. Will return null if no job id is available, ie for uncoupled search flows.
 * @returns {number | null} jobId
 */
function useJobId(): number | null {
  const { pathname, search } = useLocation();
  const params = matchParams(pathname);

  if (params.jobId) {
    return Number(params.jobId);
  }

  const { jobId } = queryStringService.parseParams(search, {
    jobId: { type: parsers.number, defaultValue: null },
  });

  return jobId;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default useJobId;

import { CompatRoute } from 'react-router-dom-v5-compat';
import { compose } from 'redux';

import withAccountToggle from './withAccountToggle/withAccountToggle';
import withAuthentication from './withAuthentication/withAuthentication';
import withRestrictions from './withRestrictions/withRestrictions';
import withUserContext from './withUserContext/withUserContext';

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default compose(
  /*
   * (Required)
   * Authenticates the user via through the Online Identity SDK
   * */
  withAuthentication,
  /*
   * (Optional)
   * Handles an account toggle if ?advertiserId=xx is provided
   * If no parameter is provided, this is ignored
   * */
  withAccountToggle,
  /*
   * (Required)
   * Fetches user context required and configures ACL layer
   * */
  withUserContext,
  /*
   * (Required)
   * Checks whether the user has permission to view the route
   * */
  withRestrictions,
)(CompatRoute);

import { ButtonLink, Box } from 'braid-design-system';

import { useGetSwitchAccountsHrefQuery } from './GetSwitchAccountsHref.generated';

export const AccountSwitcher = () => {
  const { data } = useGetSwitchAccountsHrefQuery({
    // This information is fetched already by the header component.
    // use cache-first to avoid making additional network requests.
    fetchPolicy: 'cache-first',
  });

  if (!data || !data.headerFooterContext?.advertiser?.switchAccountsHref) {
    return null;
  }

  return (
    <Box paddingX="medium" paddingTop="none" paddingBottom="small">
      <ButtonLink
        size="small"
        href={data.headerFooterContext.advertiser.switchAccountsHref}
      >
        Switch Accounts
      </ButtonLink>
    </Box>
  );
};

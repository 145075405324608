// Default exports can be ignored for this file. sku templates provide default exports as standard here
/* eslint-disable import/no-default-export */

import loadable from 'sku/@loadable/component';

import { RouteKeys, RoutePaths, type RouteDefinition, ACL } from './types';

//  NOTE: Order matters!
//  https://reacttraining.com/react-router/core/api/Route/path-string
//  react-router will always return the FIRST matching route by path
const unrestricted: RouteDefinition[] = [
  {
    key: RouteKeys.Error,
    path: RoutePaths.ErrorCustom,
    exact: true,
    component: loadable(() => import('./scenes/Error/ErrorContainer')),
  },
  {
    key: RouteKeys.WithLanguageError,
    path: RoutePaths.WithLanguageErrorCustom,
    exact: true,
    component: loadable(() => import('./scenes/Error/ErrorContainer')),
  },
  {
    key: RouteKeys.Error,
    path: RoutePaths.Error,
    exact: true,
    component: loadable(() => import('./scenes/Error/ErrorContainer')),
  },
  {
    key: RouteKeys.WithLanguageError,
    path: RoutePaths.WithLanguageError,
    exact: true,
    component: loadable(() => import('./scenes/Error/ErrorContainer')),
  },
];

const recommendedCandidates = [
  {
    key: RouteKeys.RecommendedCandidates,
    path: RoutePaths.RecommendedCandidates,
    exact: true,
    component: loadable(() => import('./scenes/RecommendedCandidates')),
  },
  {
    key: RouteKeys.WithLanguageRecommendedCandidates,
    path: RoutePaths.WithLanguageRecommendedCandidates,
    exact: true,
    component: loadable(() => import('./scenes/RecommendedCandidates')),
  },
];

const restricted: RouteDefinition[] = [
  {
    key: RouteKeys.Pooling,
    path: RoutePaths.Pooling,
    exact: true,
    component: loadable(() => import('./scenes/Pooling')),
    restrictions: {
      aclFeatureKey: ACL.SCENE_FEATURE.POOLING,
    },
  },
  {
    key: RouteKeys.WithLanguagePooling,
    path: RoutePaths.WithLanguagePooling,
    exact: true,
    component: loadable(() => import('./scenes/Pooling')),
    restrictions: {
      aclFeatureKey: ACL.SCENE_FEATURE.POOLING,
    },
  },
  {
    key: RouteKeys.PoolingChannel,
    path: RoutePaths.PoolingChannel,
    exact: true,
    component: loadable(() => import('./scenes/PoolingChannel')),
    restrictions: {
      aclFeatureKey: ACL.SCENE_FEATURE.POOLING,
    },
  },
  {
    key: RouteKeys.WithLanguagePoolingChannel,
    path: RoutePaths.WithLanguagePoolingChannel,
    exact: true,
    component: loadable(() => import('./scenes/PoolingChannel')),
    restrictions: {
      aclFeatureKey: ACL.SCENE_FEATURE.POOLING,
    },
  },
  {
    key: RouteKeys.Profile,
    path: RoutePaths.Profile,
    exact: true,
    component: loadable(() => import('./scenes/Profile/ProfileContainer')),
  },
  {
    key: RouteKeys.WithLanguageProfile,
    path: RoutePaths.WithLanguageProfile,
    exact: true,
    component: loadable(() => import('./scenes/Profile/ProfileContainer')),
  },
  {
    key: RouteKeys.SearchResults,
    path: RoutePaths.SearchResultsCoupled,
    exact: true,
    component: loadable(() => import('./scenes/Search/CoupledSearch')),
  },
  {
    key: RouteKeys.WithLanguageSearchResults,
    path: RoutePaths.WithLanguageSearchResultsCoupled,
    exact: true,
    component: loadable(() => import('./scenes/Search/CoupledSearch')),
  },
  {
    key: RouteKeys.Search,
    path: RoutePaths.SearchCoupled,
    exact: true,
    component: loadable(() => import('./scenes/Search/CoupledSearchLanding')),
  },
  {
    key: RouteKeys.WithLanguageSearch,
    path: RoutePaths.WithLanguageSearchCoupled,
    exact: true,
    component: loadable(() => import('./scenes/Search/CoupledSearchLanding')),
  },
  {
    key: RouteKeys.SearchResults,
    path: RoutePaths.SearchResultsUncoupled,
    exact: true,
    component: loadable(() => import('./scenes/Search/UncoupledSearch')),
    restrictions: {
      aclFeatureKey: ACL.SCENE_FEATURE.SEARCH_UNCOUPLED,
    },
  },
  {
    key: RouteKeys.WithLanguageSearchResults,
    path: RoutePaths.WithLanguageSearchResultsUncoupled,
    exact: true,
    component: loadable(() => import('./scenes/Search/UncoupledSearch')),
    restrictions: {
      aclFeatureKey: ACL.SCENE_FEATURE.SEARCH_UNCOUPLED,
    },
  },
  {
    key: RouteKeys.Search,
    path: RoutePaths.SearchUncoupled,
    exact: true,
    component: loadable(() => import('./scenes/Search/UncoupledSearchLanding')),
    restrictions: {
      aclFeatureKey: ACL.SCENE_FEATURE.SEARCH_UNCOUPLED,
    },
  },
  {
    key: RouteKeys.WithLanguageSearch,
    path: RoutePaths.WithLanguageSearchUncoupled,
    exact: true,
    component: loadable(() => import('./scenes/Search/UncoupledSearchLanding')),
    restrictions: {
      aclFeatureKey: ACL.SCENE_FEATURE.SEARCH_UNCOUPLED,
    },
  },
  {
    key: RouteKeys.SimilarCandidates,
    path: RoutePaths.SimilarCandidates,
    exact: true,
    component: loadable(() => import('./scenes/SimilarCandidates')),
    restrictions: {
      aclFeatureKey: ACL.SCENE_FEATURE.SIMILAR_CANDIDATES,
    },
  },
  {
    key: RouteKeys.WithLanguageSimilarCandidates,
    path: RoutePaths.WithLanguageSimilarCandidates,
    exact: true,
    component: loadable(() => import('./scenes/SimilarCandidates')),
    restrictions: {
      aclFeatureKey: ACL.SCENE_FEATURE.SIMILAR_CANDIDATES,
    },
  },
  {
    key: RouteKeys.Help,
    path: RoutePaths.Help,
    exact: true,
    component: loadable(() => import('./scenes/Help')),
  },
  {
    key: RouteKeys.WithLanguageHelp,
    path: RoutePaths.WithLanguageHelp,
    exact: true,
    component: loadable(() => import('./scenes/Help')),
  },
  {
    key: RouteKeys.Root,
    path: RoutePaths.Root,
    exact: true,
    component: loadable(() => import('./scenes/Root/RootContainer')),
  },
  {
    key: RouteKeys.WithLanguageRoot,
    path: RoutePaths.WithLanguageRoot,
    exact: true,
    component: loadable(() => import('./scenes/Root/RootContainer')),
  },
];

export default {
  unrestricted,
  restricted,
  recommendedCandidates,
};

import errorService from '../../services/error';
import {
  type Message,
  APIErrorCode,
  type AxiosOrGraphQLError,
} from '../../types';

type Option =
  | { key: APIErrorCode.BAD_REQUEST_INSUFFICIENT_CREDITS }
  | { key: APIErrorCode.BAD_REQUEST_NO_CREDITS }
  | { key: APIErrorCode.GONE_RESUME_NOT_PUBLIC }
  | { key: APIErrorCode.FORBIDDEN_JOB_EXPIRED }
  | { key: APIErrorCode.FORBIDDEN_BETA_TEST_ENVIRONMENT_ACCESS };

const DEFAULT_TONE = 'critical';

function getMessageTemplate(option: Option): Message | null {
  switch (option.key) {
    case APIErrorCode.BAD_REQUEST_INSUFFICIENT_CREDITS:
      return {
        message: '',
        translation: {
          key: 'BAD_REQUEST_INSUFFICIENT_CREDITS',
        },
        tone: DEFAULT_TONE,
      };

    case APIErrorCode.BAD_REQUEST_NO_CREDITS:
      return {
        message: '',
        translation: {
          key: 'BAD_REQUEST_NO_CREDITS',
        },
        tone: DEFAULT_TONE,
      };

    case APIErrorCode.GONE_RESUME_NOT_PUBLIC:
      return {
        message: '',
        translation: {
          key: 'GONE_RESUME_NOT_PUBLIC',
        },
        tone: DEFAULT_TONE,
      };

    case APIErrorCode.FORBIDDEN_JOB_EXPIRED:
      return {
        message: '',
        translation: {
          key: 'FORBIDDEN_JOB_EXPIRED',
        },
        tone: DEFAULT_TONE,
      };

    case APIErrorCode.FORBIDDEN_BETA_TEST_ENVIRONMENT_ACCESS:
      return {
        message: '',
        translation: {
          key: 'FORBIDDEN_BETA_TEST_ENVIRONMENT_ACCESS',
        },
        tone: DEFAULT_TONE,
      };

    default:
      return null;
  }
}

function createMessageFromError<T>(
  error: AxiosOrGraphQLError,
  params?: T,
): Message | null {
  const { apiErrorCode: key } = errorService.getAPIErrorCodeFromError(error);
  if (!key) {
    return null;
  }

  return getMessageTemplate({
    key,
    ...(params && { params }),
  } as Option);
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default createMessageFromError;

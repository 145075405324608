import { useTranslations } from '@vocab/react';
import { Box, Text } from 'braid-design-system';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useConstantUrls } from 'src/hooks/useConstUrls/useConstantUrls';
import { useRoutes } from 'src/hooks/useRoutes';

import useACL from '../../../../../../../hooks/useACL';
import { selectors as routerSelectors } from '../../../../../../../store/router';
import { selectors as userSelectors } from '../../../../../../../store/user';
import translations from '../../PageLinks/.vocab';
import MenuLink from '../MenuLink/MenuLink';

import { getPageLinks } from './../../PageLinks/getPageLinks';

import * as styles from './PageLinks.css';

interface Props {
  onSelect: () => void;
}

const PageLinks = ({ onSelect }: Props) => {
  const { getProductConfig } = useACL();
  const { routes } = useRoutes();

  const advertiserId = useSelector(userSelectors.getAdvertiserIdSelector);
  const jobId = useSelector(routerSelectors.getActiveJobIdSelector);
  const { t } = useTranslations(translations);
  const { creditDashboardUrl } = useConstantUrls();
  const pageLinks = getPageLinks(
    getProductConfig().links,
    {
      creditDashboardUrl,
      advertiserId,
      jobId,
      t,
    },
    routes,
  );

  return useMemo(
    () => (
      <Box display="flex" flexGrow={1} alignItems="center">
        {pageLinks.map(
          (
            {
              href,
              hasCreditsMetricsEvent,
              isExternal,
              isActive,
              children,
              hasCredits,
            },
            index,
          ) => (
            // Todo - remove this class
            // Rely on the parent to control spacing between children + vertical alignment
            <Box
              className={classNames({
                [styles.isLast]: hasCredits,
                credits: hasCredits,
              })}
              key={index}
            >
              <MenuLink
                key={index}
                href={href}
                hasCreditsMetricsEvent={hasCreditsMetricsEvent}
                isExternal={isExternal}
                isActive={isActive}
                onClick={onSelect}
              >
                <Box
                  className={styles.containerWithBorder}
                  display="flex"
                  alignItems="center"
                >
                  <Box>
                    <Text size="small">{children}</Text>
                  </Box>
                </Box>
              </MenuLink>
            </Box>
          ),
        )}
      </Box>
    ),
    [pageLinks, onSelect],
  );
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default PageLinks;

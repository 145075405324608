
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoiQ3JlZGl0cyJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoiQ3JlZGl0cyJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoi4LmA4LiE4Lij4LiU4Li04LiVIn0=!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoi4LmA4LiE4Lij4LiU4Li04LiVIn0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJEYWZ0YXIgS2FuZGlkYXQiLCJDUkVESVRTIjoiS3JlZGl0In0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJEYWZ0YXIgS2FuZGlkYXQiLCJDUkVESVRTIjoiS3JlZGl0In0=!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoiQ3JlZGl0cyJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoiQ3JlZGl0cyJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoiQ3JlZGl0cyJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoiQ3JlZGl0cyJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoiQ3JlZGl0cyJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoiQ3JlZGl0cyJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoiQ3JlZGl0cyJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoiQ3JlZGl0cyJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoiQ3JlZGl0cyJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoiQ3JlZGl0cyJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoiQ3JlZGl0cyJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoiQ3JlZGl0cyJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoiQ3JlZGl0cyJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoiQ3JlZGl0cyJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoiQ3JlZGl0cyJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoiQ3JlZGl0cyJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoiQ3JlZGl0cyJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoiQ3JlZGl0cyJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoi4LmA4LiE4Lij4LiU4Li04LiVIn0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoi4LmA4LiE4Lij4LiU4Li04LiVIn0=!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJEYWZ0YXIgS2FuZGlkYXQiLCJDUkVESVRTIjoiS3JlZGl0In0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJEYWZ0YXIgS2FuZGlkYXQiLCJDUkVESVRTIjoiS3JlZGl0In0=!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoiQ3JlZGl0cyJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiVGFsZW50IFNlYXJjaCIsIlRBTEVOVF9TRUFSQ0hfQllfSk9CIjoiQ2FuZGlkYXRlIE1hdGNoZXMiLCJUQUxFTlRfUE9PTFMiOiJUYWxlbnQgUG9vbHMiLCJDUkVESVRTIjoiQ3JlZGl0cyJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiW+G5rsSDxIPEg8aa4bq94bq94bq94LiB4Li14LmJ4bmvIMWg4bq94bq94bq9xIPEg8SDxZnDp+G4qV0iLCJUQUxFTlRfU0VBUkNIX0JZX0pPQiI6IlvDh8SDxIPEg+C4geC4teC5icaMw6zDrMOsxozEg8SDxIPhua/hur3hur3hur0g4bmCxIPEg8SD4bmvw6fhuKnhur3hur3hur3FoV0iLCJUQUxFTlRfUE9PTFMiOiJb4bmuxIPEg8SDxprhur3hur3hur3guIHguLXguYnhua8gxqTDtsO2w7bDtsO2w7bGmsWhXSIsIkNSRURJVFMiOiJbw4fFmeG6veG6veG6vcaMw6zDrMOs4bmvxaFdIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUQUxFTlRfU0VBUkNIIjoiW+G5rsSDxIPEg8aa4bq94bq94bq94LiB4Li14LmJ4bmvIMWg4bq94bq94bq9xIPEg8SDxZnDp+G4qV0iLCJUQUxFTlRfU0VBUkNIX0JZX0pPQiI6IlvDh8SDxIPEg+C4geC4teC5icaMw6zDrMOsxozEg8SDxIPhua/hur3hur3hur0g4bmCxIPEg8SD4bmvw6fhuKnhur3hur3hur3FoV0iLCJUQUxFTlRfUE9PTFMiOiJb4bmuxIPEg8SDxprhur3hur3hur3guIHguLXguYnhua8gxqTDtsO2w7bDtsO2w7bGmsWhXSIsIkNSRURJVFMiOiJbw4fFmeG6veG6veG6vcaMw6zDrMOs4bmvxaFdIn0=!"
        )
      )
      });
  
      export { translations as default };
    
/* eslint-disable @typescript-eslint/no-shadow */
import type { ApolloError } from '@apollo/client';
import type { ErrorResponse } from '@apollo/client/link/error';
import type { Zone } from '@seek/audience-zones';
import type {
  Locale,
  SiteNamesMatchingClassification,
  Brand,
  Country,
  Language as MelwaysLanguage,
  siteConfig,
} from '@seek/melways-sites';
import {
  APIErrorCode,
  type APIErrorResponse,
  type HirerAccount,
  OpaqueErrorId,
} from '@seek/talentsearch-entity-types';
import type { AxiosError } from 'axios';
import type { Badge } from 'braid-design-system';
import type { Action as BaseAction } from 'redux';
import type loadable from 'sku/@loadable/component';

import type { GetTalentSearchJobQuery } from 'src/services/data/jobs/GetTalentSearchJob.generated';
import type { rootReducer } from 'src/store';

import type { FeatureKey } from './acl';
import {
  type SalaryFrequency,
  type TalentSearchAssignedPool,
  TalentSearchClaim,
  TalentSearchContractType,
  type TalentSearchCountResult,
  type TalentSearchCountResultWithLabel,
  type TalentSearchLatestInteraction,
  type TalentSearchPool,
  type TalentSearchProfileResultV2,
  type TalentSearchSendJobStatus,
  type TalentSearchSavedSearch,
  type TalentSearchCriteria,
} from './generated';
import * as Interactions from './interactions';
import * as Search from './search';
import * as Services from './services';
import * as Settings from './settings';
import * as Tags from './tags';

export type { Language as MelwaysLanguage } from '@seek/melways-sites';

export * as ACL from './acl';

export interface TealiumGlobalProperties {
  brand: Brand;
  siteCountry: Country | null;
  siteLanguage: MelwaysLanguage;
  zone: Zone;
  currentPage: string;
  isLoggedIn: boolean;
  siteSection: 'talent search';
}

export enum SiteSections {
  TalentSearch = 'talent search',
  Jobs = 'jobs',
}

export enum SiteSubsections {
  TalentSearch = 'talent search',
}

export {
  Search,
  Settings,
  Tags,
  Services,
  Interactions,
  APIErrorCode,
  type APIErrorResponse,
  OpaqueErrorId,
  TalentSearchClaim,
  TalentSearchContractType,
};

/*
  Generated types from apollo codegen.
 */
export type TalentSearchJobV2 = NonNullable<
  GetTalentSearchJobQuery['talentSearchJob']
>;

/*
  Generated interfaces from GraphQL typeDefs
 */
export type {
  TalentSearchAccount as Account,
  TalentSearchAccounts,
  TalentSearchJobsV2,
  TalentSearchAtsJob as ATSJob,
  TalentSearchMetadataIndustry as MetadataIndustry,
  TalentSearchMetadataSubIndustry as MetadataSubIndustry,
  TalentSearchMetadataQueryValidations as MetadataQueryValidations,
  TalentSearchInteractionsConnection as InteractionsConnection,
  TalentSearchInteractionEdge as InteractionEdge,
  TalentSearchLatestInteraction as LatestInteraction,
  TalentSearchAssignedPools as AssignedPools,
  TalentSearchAssignedPool as AssignedPool,
  TalentSearchPool,
  TalentSearchAssignedPoolsInput as AssignedPoolsInput,
  TalentSearchCreateTalentPoolInput as CreateTalentPoolInput,
  TalentSearchDeleteTalentPoolInput as DeleteTalentPoolInput,
  TalentSearchUpdateTalentPoolInput as UpdateTalentPoolInput,
  TalentSearchShareTalentPoolInput as ShareTalentPoolInput,
  TalentSearchAddOrRemoveProfileTalentPoolsInput as AddOrRemoveProfilePoolsInput,
  TalentSearchAddOrRemoveProfileTalentPoolsValuesInput as AddOrRemoveProfilePoolsValuesInput,
  TalentSearchAvailableJobV2 as AvailableJobV2,
  TalentSearchMessageDetails as MessageDetails,
  TalentSearchHirerContactDetails as HirerDetails,
  TalentSearchConnectionAvailability as ConnectionAvailability,
  TalentSearchProfileSendJobStatus as ProfileSendJobStatus,
  TalentSearchAtsJobsInput as ATSJobsInput,
  TalentSearchAtsJobsConnection as ATSJobsConnection,
  TalentSearchSimilarCandidatesByIdInputV2 as SimilarCandidatesByIdInputV2,
  TalentSearchSimilarCandidatesResultV2 as SimilarCandidatesResultV2,
  TalentSearchCountsResults as FilterCounts,
  TalentSearchProfileResumeInput,
  TalentSearchProfileResumeResult as Resume,
  TalentSearchFeature,
  TalentSearchUserContext as UserContext,
  TalentSearchUserContextInput as UserContextInput,
  Location,
} from './generated';

export type FilterCount = TalentSearchCountResult;
export type FilterCountWithLabel = TalentSearchCountResultWithLabel;

/*
  Generated enums from GraphQL typeDefs
 */
export {
  TalentSearchMetadataCulture as MetadataCulture,
  ProfileSource,
  TalentSearchSendJobStatus as SendJobStatus,
  TalentSearchConnectionStatus as ConnectionStatus,
  type TalentSearchCountsResults,
} from './generated';

export type CriteriaKey =
  | Search.CriteriaKey.Approachable
  | Search.CriteriaKey.Industry
  | Search.CriteriaKey.Company
  | Search.CriteriaKey.CV
  | Search.CriteriaKey.Keywords
  | Search.CriteriaKey.LastUpdated
  | Search.CriteriaKey.LocationList
  | Search.CriteriaKey.SalaryMax
  | Search.CriteriaKey.SalaryMin
  | Search.CriteriaKey.Nation
  | Search.CriteriaKey.SalaryNation
  | Search.CriteriaKey.SalaryType
  | Search.CriteriaKey.SalaryUnspecified
  | Search.CriteriaKey.UncoupledFreeText
  | Search.CriteriaKey.Visa
  | Search.CriteriaKey.WorkType
  | Search.CriteriaKey.WillingToRelocate
  | Search.CriteriaKey.ProfileTypes
  | Search.CriteriaKey.RightToWorkIds
  | Search.CriteriaKey.RightToWorkUnspecified;

export interface CriteriaType {
  [Search.CriteriaKey.Approachable]: boolean;
  [Search.CriteriaKey.Industry]: string[];
  [Search.CriteriaKey.Company]: string[];
  [Search.CriteriaKey.CV]: boolean;
  [Search.CriteriaKey.Keywords]: string;
  [Search.CriteriaKey.LastUpdated]: string;
  [Search.CriteriaKey.LocationList]: number[];
  [Search.CriteriaKey.SalaryMax]: number;
  [Search.CriteriaKey.SalaryMin]: number;
  [Search.CriteriaKey.Nation]: number;
  [Search.CriteriaKey.SalaryNation]: number;
  [Search.CriteriaKey.SalaryType]: string;
  [Search.CriteriaKey.SalaryUnspecified]: boolean;
  [Search.CriteriaKey.UncoupledFreeText]: string;
  [Search.CriteriaKey.Visa]: string[];
  [Search.CriteriaKey.WorkType]: string[];
  [Search.CriteriaKey.WillingToRelocate]: boolean;
  [Search.CriteriaKey.ProfileTypes]: string[];
  [Search.CriteriaKey.RightToWorkIds]: string[];
  [Search.CriteriaKey.RightToWorkUnspecified]: boolean;
}

const BASE_SEARCH_PARAM_KEYS = {
  Approachable: 'approachable',
  Keywords: 'keywords',
  CV: 'cv',
  Company: 'company',
  SalaryMin: 'minSalary',
  SalaryMax: 'maxSalary',
  SalaryNation: 'salaryNation',
  SalaryType: 'salaryType',
  SalaryUnspecified: 'salaryUnspecified',
  Visa: 'visa',
  LastUpdated: 'lastUpdated',
  Industry: 'industry',
  LocationList: 'locationList',
  WillingToRelocate: 'willingToRelocate',
  WorkType: 'workType',
  Nation: 'nation',
  ProfileTypes: 'profileTypes',
  SortBy: 'sortBy',
  PageNumber: 'pageNumber',
  UncoupledFreeText: 'uncoupledFreeText',
} as const;

export type SearchParams = {
  [P in (typeof BASE_SEARCH_PARAM_KEYS)[keyof typeof BASE_SEARCH_PARAM_KEYS]]?: string;
};

export interface WorkHistory {
  companyName: string;
  description: string | null;
  duration: string | null;
  endDate: string | null;
  foundInCV: boolean;
  jobTitle: string;
  startDate: string;
  highlight: {
    description?: string;
    companyName?: string;
    jobTitle?: string;
  };
}

// Connections related iterfaces that are used internally in UI
interface ConnectionMethodBase {
  isUsed: boolean;
  lastConnectionTimestamp?: number;
  connectionId?: string | null;
  isPending: boolean;
  hasError: boolean;
}

export interface AccessCv extends ConnectionMethodBase {
  nextAvailableConnectionTimestamp?: number;
}

export interface SendJob extends ConnectionMethodBase {
  status?: TalentSearchSendJobStatus;
  nextAvailableConnectionTimestamp?: number;
}

export interface SendMessage extends ConnectionMethodBase {
  nextAvailableConnectionTimestamp?: number;
}
export type ExportProfile = ConnectionMethodBase;

export interface ConnectionMethod {
  accessCv: AccessCv;
  sendMessage: SendMessage;
  sendJob: SendJob;
  exportProfile: ExportProfile;
}

export interface ProfileWorkHistoryHighlight {
  description?: string;
  companyName?: string;
  jobTitle?: string;
}

export type CompactProfileV2 = Pick<
  TalentSearchProfileResultV2,
  | 'profileId'
  | 'firstName'
  | 'lastName'
  | 'currentJobTitle'
  | 'lastModifiedDate'
  | 'approachabilitySignal'
  | 'profileType'
  | 'workHistories'
  | 'hasVerifiedCredentials'
  | 'digitalIdentity'
>;

export type CompleteProfileV2 = Pick<
  TalentSearchProfileResultV2,
  | 'profileId'
  | 'partnerDoNotContact'
  | 'firstName'
  | 'lastName'
  | 'currentJobTitle'
  | 'lastModifiedDate'
  | 'atsLastUpdatedDateUtc'
  | 'approachabilitySignal'
  | 'salary'
  | 'suburb'
  | 'state'
  | 'country'
  | 'currentLocation'
  | 'profileType'
  | 'profilePrivacy'
  | 'hasResume'
  | 'workHistories'
  | 'actionLinks'
  | 'hasVerifiedCredentials'
  | 'nationalities'
  | 'digitalIdentity'
>;

export interface ProfileV2
  extends TalentSearchProfileResultV2,
    Pick<
      AsyncProfileState,
      'sendJob' | 'sendMessage' | 'accessCv' | 'exportProfile'
    > {}

export interface SimilarCandidateExtract {
  currentJobTitle?: ProfileV2['currentJobTitle'];
  firstName?: ProfileV2['firstName'];
  lastName?: ProfileV2['lastName'];
}

/*
  ^ To be moved to hirer graph generated types ^
 */
export interface AsyncProfileState {
  //  Fields resolved from the getAsyncProfileFeatures request
  assignedPools: TalentSearchAssignedPool[];
  latestInteraction: TalentSearchLatestInteraction | null;
  hasConnectionHistory: boolean;
  sendJob: SendJob;
  sendMessage: SendMessage;
  accessCv: AccessCv;
  exportProfile: ExportProfile;
  position: number; // Captured from the full profiles response
}

export interface AsyncState {
  entries: Record<string, AsyncProfileState>;
  loading: boolean;
  error: boolean;
}

export enum PrivacyTypes {
  Standard = 'standard',
  Limited = 'limited',
}

export enum ProfileTypes {
  Seek = 'seek',
  Combined = 'combined',
  Partner = 'partner',
  Shared = 'shared',
}

export enum ProductType {
  Connected = 'ConnectedTalentSearch',
  Premium = 'PremiumTalentSearch',
  Standard = 'StandardTalentSearch',
}

export enum Environment {
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

export interface ClientContext {
  site: EmployerSiteName;
  environment: Environment;
  locale: Locale;
  language: Language;
  name: string;
  version: string;
  branch: string;
}

export interface DatadogRumConfig {
  applicationId: string;
  clientToken: string;
  service: string;
}

export type EmployerSiteName = SiteNamesMatchingClassification<{
  product: 'employer';
}>;

export type SiteWithCountry = SiteNamesMatchingClassification<{
  country: Country;
}>;
export interface AppConfig {
  TRACKING_URL: string;
  APP_NAME: string;
  APP_VERSION: string;
  BRANCH_NAME: string;
  ROUTER_BASE_NAME: string;
  TEALIUM_URL: string;
  HIRER_GRAPH_URL: string;
  ENVIRONMENT: Environment;
  COUNTRY_CODE: CountryCodes;
  IS_LEGACY_SITE: boolean;
  LANGUAGE: Language;
  LOCALE: Locale;
  DATADOG_RUM_CONFIG: DatadogRumConfig;
  SUPPORTED_LANGUAGES: Language[];
  SITE: EmployerSiteName;
}

declare global {
  interface Window {
    hj: any;
    SEEK: AppConfig;
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    utag_data: any;
    utag_cfg_ovrd: {
      noview: boolean;
    };
    utag: {
      link: any;
      view: any;
    };
    SEEK_TEST?: boolean;
  }
}

export interface GraphResponse<T> {
  data: T | null;
  errors: string[] | null;
}

//  Redux
export type RootState = ReturnType<typeof rootReducer>;
export interface Action<T = any> extends BaseAction {
  value: T;
}

//  Feature flags
//  ---------------------------------------------------

export enum FeatureFlags {
  // Add new feature flags here
  SampleFeatureFlag = 'SampleFeatureFlag', // This is just a placeholder. When a feature flag is added, please replace this one.
}

//  Experiments
//  ---------------------------------------------------
export enum ExperimentName {
  MY_EXPERIMENT = 'my-experiment',
}

export enum BucketName {
  BUCKET_ONE = 'bucket_one',
}

//  Routes / Scenes / Paths
//  ---------------------------------------------------

export enum RouteKeys {
  Root = 'root',
  Credits = 'credits',
  Profile = 'profile',
  Pooling = 'pooling',
  PoolingChannel = 'poolingChannel',
  Search = 'search',
  SearchResults = 'searchResults',
  SimilarCandidates = 'similarCandidates',
  Error = 'error',
  Help = 'help',
  RecommendedCandidates = 'recommendedCandidates',
  WithLanguageRoot = 'withLanguageRoot',
  WithLanguageCredits = 'withLanguageCredits',
  WithLanguageProfile = 'withLanguageProfile',
  WithLanguagePooling = 'withLanguagePooling',
  WithLanguagePoolingChannel = 'withLanguagePoolingChannel',
  WithLanguageSearch = 'withLanguageSearch',
  WithLanguageSearchResults = 'withLanguageSearchResults',
  WithLanguageSimilarCandidates = 'withLanguageSimilarCandidates',
  WithLanguageError = 'withLanguageError',
  WithLanguageHelp = 'withLanguageHelp',
  WithLanguageRecommendedCandidates = 'withLanguageRecommendedCandidates',
}

export const ExternalRoutes = {
  VerifiedCredentials:
    'https://seekpass.co/seek-employers/?utm_source=seek&utm_medium=referral&utm_campaign=talent-search&utm_content=generic',
};

export enum RoutePaths {
  Root = '/talentsearch/',
  Profile = '/talentsearch/profile/:profileId',
  Pooling = '/talentsearch/pooling',
  PoolingChannel = '/talentsearch/pooling/:channel/:id',
  SearchCoupled = '/talentsearch/search/job',
  SearchUncoupled = '/talentsearch/search',
  SearchResultsCoupled = '/talentsearch/search/job/:jobId/profiles',
  SearchResultsUncoupled = '/talentsearch/search/profiles',
  SimilarCandidates = '/talentsearch/similar-candidates/:seedProfileId',
  Error = '/talentsearch/error',
  ErrorCustom = '/talentsearch/error/:errorType',
  Help = '/talentsearch/help',
  RecommendedCandidates = '/candidates/recommended',
  WithLanguageRoot = '/:language/talentsearch/',
  WithLanguageProfile = '/:language/talentsearch/profile/:profileId',
  WithLanguagePooling = '/:language/talentsearch/pooling',
  WithLanguagePoolingChannel = '/:language/talentsearch/pooling/:channel/:id',
  WithLanguageSearchCoupled = '/:language/talentsearch/search/job',
  WithLanguageSearchUncoupled = '/:language/talentsearch/search',
  WithLanguageSearchResultsCoupled = '/:language/talentsearch/search/job/:jobId/profiles',
  WithLanguageSearchResultsUncoupled = '/:language/talentsearch/search/profiles',
  WithLanguageSimilarCandidates = '/:language/talentsearch/similar-candidates/:seedProfileId',
  WithLanguageError = '/:language/talentsearch/error',
  WithLanguageErrorCustom = '/:language/talentsearch/error/:errorType',
  WithLanguageHelp = '/:language/talentsearch/help',
  WithLanguageRecommendedCandidates = '/:language/candidates/recommended',
}

export enum LocalHosts {
  AU = 'talent.seek.com.au.local',
  NZ = 'talent.seek.co.nz.local',
  HK = 'hk.employer.seek.com.local',
  MY = 'my.employer.seek.com.local',
  PH = 'ph.employer.seek.com.local',
  SG = 'sg.employer.seek.com.local',
  ID = 'id.employer.seek.com.local',
  TH = 'th.employer.seek.com.local',
}

export interface RouteDefinition {
  key: RouteKeys;
  path: RoutePaths | '*';
  exact: boolean;
  component: ReturnType<typeof loadable>;
  restrictions?: {
    aclFeatureKey?: FeatureKey;
    featureFlags?: any[];
  };
}

export enum KeyMap {
  Escape = 'Escape',
  Enter = 'Enter',
  Tab = 'Tab',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
}

export type AxiosOrGraphQLError = AxiosError | ErrorResponse | ApolloError;

export enum ErrorTypes {
  SESSION_TIMEOUT = 'sessionTimeout',
  UNAUTHORISED = 'unauthorised',
  UNKNOWN = 'unknown',
  INVALID_STATE = 'invalidState',
  SEARCH_ERROR = 'searchError',
  SEARCH_TALENT_POOL_ERROR = 'searchTalentPoolError',
}

export enum GraphQLErrorCode {
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  FORBIDDEN = 'FORBIDDEN',
}

//  Search
//  ---------------------------------------------------------

export enum FeatureParamKeys {
  OpenProfileId = 'openProfileId',
  ProfileTab = 'profileTab',
}

export enum ProfileListDefaults {
  ItemsPerPage = 20,
  PlaceholderCount = 4,
}

export enum FilterDelimiter {
  Company = ';',
}

//  Saved searches
//  ---------------------------------------------------

export enum SavedSearchesDefaults {
  Limit = 10,
  CharacterLimit = 40,
}

export type SavedSearch = TalentSearchSavedSearch & {
  criteria?: TalentSearchCriteria & { rightToWorkIds?: string[] };
};

//  Jobs
//  ---------------------------------------------------
export enum CountryCodes {
  AU = 'AU',
  NZ = 'NZ',
  HK = 'HK',
  TH = 'TH',
  MY = 'MY',
  SG = 'SG',
  ID = 'ID',
  PH = 'PH',
}

export enum Nations {
  AU = 3000,
  NZ = 3001,
  UK = 3002,
  HK = 24469,
  ID = 24473,
  TH = 24572,
  MY = 24500,
  SG = 24553,
  PH = 24534,
}

export const NATIONS = Object.values(Nations);

export enum SalaryNations {
  AUD = 'AUD',
  NZD = 'NZD',
  HKD = 'HKD',
  IDR = 'IDR',
  MYR = 'MYR',
  PHP = 'PHP',
  SGD = 'SGD',
  THB = 'THB',
}

export interface SalaryRange {
  max: number;
  min: number;
  type: SalaryFrequency;
}

export enum SalaryTypeOptions_Hourly {
  TYPE = 'HourlyRate',
  UNSPECIFIED = 'false',
  NATION = 3000,
}

export enum SalaryTypeOptions_Annual {
  TYPE = 'Annual',
  UNSPECIFIED = 'false',
  NATION = 3000,
}

//  Pools
//  ---------------------------------------------------
export enum PoolChannels {
  Pool = 'pool',
}

export enum PoolDefaults {
  CharacterLimit = 50,
}

export enum PoolActionTypes {
  CREATE = 'create',
  DELETE = 'delete',
  EDIT = 'edit',
  FETCH = 'fetch',
}

export interface Pool
  extends Pick<
    TalentSearchPool,
    'id' | 'name' | 'profileCount' | 'policy' | 'userId'
  > {
  channel: PoolChannels;
}

export interface PoolItem {
  id: string;
  name: string;
  profileCount?: number;
  policy?: string;
  userId?: number;
}

export interface UserPoolItem extends PoolItem {
  isChecked: boolean;
}

export interface UserPoolSelection {
  poolId: string;
  addToPool: boolean;
  name: string;
}

export type NormalisedUserPools = Record<string, UserPoolItem>;

//  Connections
//  ---------------------------------------------------

export enum ConnectionStatusTypes {
  Pending = 'pending',
  Redeemed = 'redeemed',
  Error = 'error',
}

// Interactions
// ----------------------------------------------------

export interface Interaction {
  userFirstName?: string;
  userLastName?: string;
  connectionsType?: number;
  sentJobTitle?: string;
  sentJobId?: number;
  sentMessageSubject?: string;
  sentMessageBody?: string;
  appliedJobTitle?: string;
  appliedJobId?: number;
  partnerNotes?: string;
  timestamp: string;
}

//  Profile types
//  ---------------------------------------------------
export enum ProfileTabNames {
  Profile = 'Profile',
  CvPreview = 'CV Preview',
  NoCv = 'No CV preview',
  Interactions = 'Interactions',
  NoInteractions = 'No Interactions',
}

export enum CVPreviewStates {
  CvPreview = 'CV_PREVIEW',
  NoCv = 'NO_CV',
  CvNotParsed = 'CV_NOT_PARSED',
  CvPrivate = 'CV_PRIVATE',
}

export interface CreditRates {
  sendJob: number;
  sendMessage: number;
  accessCv: number;
  accessProfile: number;
  accessProfileAndCv: number;
  exportSeekProfile: number;
  exportSharedProfile: number;
  refreshSharedProfile: number;
  exportProfileCts: number;
}

export enum CreditRateTypes {
  SendJob = 'sendJob',
  SendMessage = 'sendMessage',
  AccessCv = 'accessCv',
  AccessProfile = 'accessProfile',
  AccessProfileAndCv = 'accessProfileAndCv',
  ExportSeekProfile = 'exportSeekProfile',
  ExportSharedProfile = 'exportSharedProfile',
  RefreshSharedProfile = 'refreshSharedProfile',
  ExportProfileCts = 'exportProfileCts',
}

export interface User {
  advertiser: {
    id: number;
    name: string;
    accountNumber: number;
    companyId: string | null;
    companyName: string;
    isPremium: boolean;
    premiumContract: {
      type: 'subscription' | 'trial';
      startDate: number;
      endDate: number;
    } | null;
    tags?: HirerAccount.HirerAccountTags;
  };
  user: {
    id: number | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    isHirer: boolean;
    isConnectedTalentSearchUser: boolean;
  };
  ats?: {
    atsId: number;
    name: string;
  };
}

export interface Contract {
  type: 'subscription' | 'trial';
  startDate: number;
  endDate: number;
}

//  Authentication
//  ---------------------------------------------------

export enum AccessMethods {
  Direct = 'direct',
  Unknown = 'unknown',
}

//  Application log / messages
//  ---------------------------------------------------

export type MessageTranslationKey =
  | 'BAD_REQUEST_INSUFFICIENT_CREDITS'
  | 'BAD_REQUEST_NO_CREDITS'
  | 'GONE_RESUME_NOT_PUBLIC'
  | 'FORBIDDEN_JOB_EXPIRED'
  | 'FORBIDDEN_BETA_TEST_ENVIRONMENT_ACCESS';

export interface Message {
  message: string;
  translation?: {
    key: MessageTranslationKey;
  };
  description?: string;
  tone: 'critical' | 'positive';
  action?: {
    label: string;
    onClick: () => void;
  };
  key?: string;
}

export enum MessageTemplateKey {
  CONNECTION_FAILURE,
  CONNECTION_SUCCESS,
  EXCEEDED_THROTTLING_LIMIT,
  GENERIC_FAILURE,
  GENERIC_FAILURE_WITH_RETRY,
  POOLING_FAILURE,
}

//  Filters
//  ---------------------------------------------------

export enum FilterKeys {
  Approachable = 'approachable',
  ProfileSource = 'profileSource',
  Keywords = 'keywords',
  Industry = 'industry',
  Company = 'company',
  LastUpdated = 'lastUpdated',
  Salary = 'salary',
  WorkType = 'workType',
  // DEPRECATED: Use RightToWork instead
  Visa = 'visa',
  RightToWork = 'rightToWork',
  CV = 'cv',
  Location = 'location',
}

export enum FilterFormFactors {
  Mobile = 'mobile',
  Desktop = 'desktop',
}

export type ToFilterState<FS> = (state: RootState) => FS;
export type FromFilterState<FS> = (filterState: FS) => any;
export type IsFilterActive = (
  searchParams: SearchParams,
  countryCode: CountryCodes,
) => boolean;
export type ResetFilter = (
  siteCountryCode?: CountryCodes,
  salaryCountryCode?: CountryCodes,
  hasFeatureFlag?: (key: FeatureFlags) => boolean,
) => any;

export enum FilterDebounceTimeoutMs {
  None = 0,
  Slow = 400,
}

export interface FilterComponent<FS = any> {
  key: FilterKeys;
  label: React.ComponentType<any>;
  badgeProps?: React.ComponentProps<typeof Badge>;
  hideWhenFetchingFilterCounts: boolean;
  debounceTimeout: FilterDebounceTimeoutMs;
  filterComponent: React.ComponentType<any>;
  pillComponent: React.ComponentType<any>;
  toState: ToFilterState<FS>;
  fromState: FromFilterState<FS>;
  isActive: IsFilterActive;
  reset: ResetFilter;
  icon: React.ComponentType<{ isActive: boolean }>;
}

export interface FilterComponentProps<FS, FC = FilterCount> {
  isOpen?: boolean;
  formFactor: FilterFormFactors;
  filterCounts: FC[];
  countForQuery: number;
  isCountsFetching: boolean;
  isCountForQueryFetching: boolean;
  onFilterClosed: (options?: any) => void;
  onFilterUpdated: (filterState: FS, options?: any) => void;
  onFilterSubmitted: (filterState: FS) => void;
}
export interface FeatureHighlightDisplayRules {
  startDate: string;
  expiryDate: string;
}

export enum FilterDelimiters {
  Company = ';',
}

export enum TalentSearchBaseUrl {
  AU = 'https://talentsearch.seek.com.au',
  NZ = 'https://talentsearch.seek.co.nz',
}

export enum Language {
  EN = 'en',
  TH = 'th',
  EN_PSEUDO = 'en-PSEUDO',
  ID = 'id',
}

export const LanguageToTextMap: { [key in Language]: string } = {
  [Language.EN]: 'English',
  [Language.ID]: 'Bahasa',
  [Language.TH]: 'ภาษาไทย',
  [Language.EN_PSEUDO]: 'Ṯẽẽẽřm̂š',
};

export type MelwaysSiteConfigKey = keyof typeof siteConfig;

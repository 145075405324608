import { getZoneFromSite, siteConfig } from '@seek/melways-sites';
import { parse } from 'query-string';

import type { useRoutes } from 'src/hooks/useRoutes';

import tealiumSelectors from '../../../metrics/tealium/selectors';
import { selectors as routerSelectors } from '../../../store/router';
import { selectors as settingsSelectors } from '../../../store/settings';
import { selectors as userSelectors } from '../../../store/user';
import {
  FeatureParamKeys,
  RouteKeys,
  type RootState,
  type TealiumGlobalProperties,
  type SiteWithCountry,
  type MelwaysLanguage,
  type MelwaysSiteConfigKey,
  SiteSections,
} from '../../../types';
import { CandidateViewTypes, Global, Profile, Search } from '../types';

import { getCurrentPage } from './getCurrentPage';

const routeToMetadataMap: Record<string, any> = {
  [RouteKeys.SearchResults]: (snapshot: RootState) =>
    tealiumSelectors.getFiltersMetaDataSelector(snapshot),
  [RouteKeys.RecommendedCandidates]: (snapshot: RootState) =>
    tealiumSelectors.getFiltersMetaDataSelector(snapshot),
};

export const eventsForSearchKeword: string[] = [
  Search.SaveSearch,
  CandidateViewTypes.CandidateCvPreviewed,
  Profile.CVPreviewTabClick,
  Profile.InteractionsTabClick,
  Profile.NoInteractionsTabClick,
  CandidateViewTypes.FullProfileView,
  Profile.ProfileTabClick,
  'candidate_profile_displayed',
  Global.HideNamesToggleOff,
  Global.HideNamesToggleOn,
  'search_results_displayed',
];

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default function (
  snapshot: RootState,
  site: MelwaysSiteConfigKey,
  language: MelwaysLanguage,
  eventName: string,
  routes: ReturnType<typeof useRoutes>['routes'],
) {
  const routeKey = routerSelectors.getActiveRouteKeySelector(snapshot);
  //  Page info metadata
  const currentPage = getCurrentPage(snapshot);
  const hasOpenProfileId = routerSelectors.getParamFromSearchSelector(
    snapshot,
    FeatureParamKeys.OpenProfileId,
  );

  //  User info metadata
  const seekAdvertiserId = String(
    userSelectors.getAdvertiserIdSelector(snapshot),
  );
  const userId = String(userSelectors.getUserIdSelector(snapshot));

  const accountType = userSelectors.getProducTypeForMetrics(snapshot, false);

  const isLoggedIn = userSelectors.isUserAuthenticatedSelector(snapshot);
  //  Localstorage metadata
  const searchIsEthicalHiringOn =
    settingsSelectors.getEthicalHiringSelector(snapshot);

  //  SEEK type metadata
  const isSearchCoupled =
    routerSelectors.getActiveRoutePathnameSelector(snapshot) ===
      // FIME: We shouldn't use React Router's pathname to check if we are on a certain page
      // Because it's not reliable e.g. when there's path params in the path
      routes.searchCoupled() ||
    Boolean(routerSelectors.getActiveJobIdSelector(snapshot));

  // search params
  const searchParams = parse(userSelectors.getSearchSelector(snapshot));

  const actionOrigin =
    currentPage === 'recommended candidates'
      ? 'recommended-candidates-profile-drawer'
      : 'profile-drawer';

  const tealiumProps: TealiumGlobalProperties = {
    siteLanguage: language as MelwaysLanguage,
    siteCountry:
      siteConfig[site as MelwaysSiteConfigKey].classification.country,
    brand: siteConfig[site as MelwaysSiteConfigKey].classification.brand,
    zone: getZoneFromSite(site as SiteWithCountry),
    currentPage,
    isLoggedIn,
    siteSection: SiteSections.TalentSearch,
    ...(searchParams.uncoupledFreeText &&
      eventsForSearchKeword.includes(eventName) && {
        searchKeyword: searchParams.uncoupledFreeText,
      }),
  };
  return {
    ...(hasOpenProfileId ? { actionOrigin } : null),
    seekAdvertiserId,
    userId,
    accountType,
    searchIsEthicalHiringOn,
    isSearchCoupled,
    ...tealiumProps,
    //  Add any additional route based meta data
    ...(routeKey in routeToMetadataMap
      ? {
          ...routeToMetadataMap[routeKey](snapshot),
          searchSortedBy: routeToMetadataMap[routeKey](snapshot).searchSortedBy,
        }
      : null),
  };
}

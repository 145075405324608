
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlNhdmVkIHNlYXJjaGVzIiwiU0FWRURfU0VBUkNIRVNfQ09VTlRfT0ZfMTAiOiJ7c2F2ZWRTZWFyY2hlc0NvdW50fSBvZiAxMCIsIkRFTEVURV9OQU1FIjoiRGVsZXRlIHtuYW1lfSIsIllFUyI6IlllcyIsIk5PIjoiTm8iLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IllvdSBoYXZlIG5vIHNhdmVkIHNlYXJjaGVzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlNhdmVkIHNlYXJjaGVzIiwiU0FWRURfU0VBUkNIRVNfQ09VTlRfT0ZfMTAiOiJ7c2F2ZWRTZWFyY2hlc0NvdW50fSBvZiAxMCIsIkRFTEVURV9OQU1FIjoiRGVsZXRlIHtuYW1lfSIsIllFUyI6IlllcyIsIk5PIjoiTm8iLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IllvdSBoYXZlIG5vIHNhdmVkIHNlYXJjaGVzLiJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IuC4geC4suC4o+C4hOC5ieC4meC4q+C4suC4l+C4teC5iOC4muC4seC4meC4l+C4tuC4geC5hOC4p+C5iSIsIlNBVkVEX1NFQVJDSEVTX0NPVU5UX09GXzEwIjoie3NhdmVkU2VhcmNoZXNDb3VudH0g4LiI4Liy4LiBIDEwIiwiREVMRVRFX05BTUUiOiLguKXguJoge25hbWV9IiwiWUVTIjoi4LmD4LiK4LmIIiwiTk8iOiLguYTguKHguYjguYPguIrguYgiLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IuC4hOC4uOC4k+C5hOC4oeC5iOC4oeC4teC4geC4suC4o+C4hOC5ieC4meC4q+C4suC4l+C4teC5iOC4muC4seC4meC4l+C4tuC4geC5hOC4p+C5iSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IuC4geC4suC4o+C4hOC5ieC4meC4q+C4suC4l+C4teC5iOC4muC4seC4meC4l+C4tuC4geC5hOC4p+C5iSIsIlNBVkVEX1NFQVJDSEVTX0NPVU5UX09GXzEwIjoie3NhdmVkU2VhcmNoZXNDb3VudH0g4LiI4Liy4LiBIDEwIiwiREVMRVRFX05BTUUiOiLguKXguJoge25hbWV9IiwiWUVTIjoi4LmD4LiK4LmIIiwiTk8iOiLguYTguKHguYjguYPguIrguYgiLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IuC4hOC4uOC4k+C5hOC4oeC5iOC4oeC4teC4geC4suC4o+C4hOC5ieC4meC4q+C4suC4l+C4teC5iOC4muC4seC4meC4l+C4tuC4geC5hOC4p+C5iSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlBlbmNhcmlhbiB0ZXJzaW1wYW4iLCJTQVZFRF9TRUFSQ0hFU19DT1VOVF9PRl8xMCI6IntzYXZlZFNlYXJjaGVzQ291bnR9IGRhcmkgMTAiLCJERUxFVEVfTkFNRSI6IkhhcHVzIHtuYW1lfSIsIllFUyI6IllhIiwiTk8iOiJUaWRhayIsIllPVV9IQVZFX05PX1NBVkVEX1NFQVJDSEVTIjoiVGlkYWsgYWRhIHBlbmNhcmlhbiB0ZXJzaW1wYW4uIn0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlBlbmNhcmlhbiB0ZXJzaW1wYW4iLCJTQVZFRF9TRUFSQ0hFU19DT1VOVF9PRl8xMCI6IntzYXZlZFNlYXJjaGVzQ291bnR9IGRhcmkgMTAiLCJERUxFVEVfTkFNRSI6IkhhcHVzIHtuYW1lfSIsIllFUyI6IllhIiwiTk8iOiJUaWRhayIsIllPVV9IQVZFX05PX1NBVkVEX1NFQVJDSEVTIjoiVGlkYWsgYWRhIHBlbmNhcmlhbiB0ZXJzaW1wYW4uIn0=!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlNhdmVkIHNlYXJjaGVzIiwiU0FWRURfU0VBUkNIRVNfQ09VTlRfT0ZfMTAiOiJ7c2F2ZWRTZWFyY2hlc0NvdW50fSBvZiAxMCIsIkRFTEVURV9OQU1FIjoiRGVsZXRlIHtuYW1lfSIsIllFUyI6IlllcyIsIk5PIjoiTm8iLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IllvdSBoYXZlIG5vIHNhdmVkIHNlYXJjaGVzLiJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlNhdmVkIHNlYXJjaGVzIiwiU0FWRURfU0VBUkNIRVNfQ09VTlRfT0ZfMTAiOiJ7c2F2ZWRTZWFyY2hlc0NvdW50fSBvZiAxMCIsIkRFTEVURV9OQU1FIjoiRGVsZXRlIHtuYW1lfSIsIllFUyI6IlllcyIsIk5PIjoiTm8iLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IllvdSBoYXZlIG5vIHNhdmVkIHNlYXJjaGVzLiJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlNhdmVkIHNlYXJjaGVzIiwiU0FWRURfU0VBUkNIRVNfQ09VTlRfT0ZfMTAiOiJ7c2F2ZWRTZWFyY2hlc0NvdW50fSBvZiAxMCIsIkRFTEVURV9OQU1FIjoiRGVsZXRlIHtuYW1lfSIsIllFUyI6IlllcyIsIk5PIjoiTm8iLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IllvdSBoYXZlIG5vIHNhdmVkIHNlYXJjaGVzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlNhdmVkIHNlYXJjaGVzIiwiU0FWRURfU0VBUkNIRVNfQ09VTlRfT0ZfMTAiOiJ7c2F2ZWRTZWFyY2hlc0NvdW50fSBvZiAxMCIsIkRFTEVURV9OQU1FIjoiRGVsZXRlIHtuYW1lfSIsIllFUyI6IlllcyIsIk5PIjoiTm8iLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IllvdSBoYXZlIG5vIHNhdmVkIHNlYXJjaGVzLiJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlNhdmVkIHNlYXJjaGVzIiwiU0FWRURfU0VBUkNIRVNfQ09VTlRfT0ZfMTAiOiJ7c2F2ZWRTZWFyY2hlc0NvdW50fSBvZiAxMCIsIkRFTEVURV9OQU1FIjoiRGVsZXRlIHtuYW1lfSIsIllFUyI6IlllcyIsIk5PIjoiTm8iLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IllvdSBoYXZlIG5vIHNhdmVkIHNlYXJjaGVzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlNhdmVkIHNlYXJjaGVzIiwiU0FWRURfU0VBUkNIRVNfQ09VTlRfT0ZfMTAiOiJ7c2F2ZWRTZWFyY2hlc0NvdW50fSBvZiAxMCIsIkRFTEVURV9OQU1FIjoiRGVsZXRlIHtuYW1lfSIsIllFUyI6IlllcyIsIk5PIjoiTm8iLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IllvdSBoYXZlIG5vIHNhdmVkIHNlYXJjaGVzLiJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlNhdmVkIHNlYXJjaGVzIiwiU0FWRURfU0VBUkNIRVNfQ09VTlRfT0ZfMTAiOiJ7c2F2ZWRTZWFyY2hlc0NvdW50fSBvZiAxMCIsIkRFTEVURV9OQU1FIjoiRGVsZXRlIHtuYW1lfSIsIllFUyI6IlllcyIsIk5PIjoiTm8iLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IllvdSBoYXZlIG5vIHNhdmVkIHNlYXJjaGVzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlNhdmVkIHNlYXJjaGVzIiwiU0FWRURfU0VBUkNIRVNfQ09VTlRfT0ZfMTAiOiJ7c2F2ZWRTZWFyY2hlc0NvdW50fSBvZiAxMCIsIkRFTEVURV9OQU1FIjoiRGVsZXRlIHtuYW1lfSIsIllFUyI6IlllcyIsIk5PIjoiTm8iLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IllvdSBoYXZlIG5vIHNhdmVkIHNlYXJjaGVzLiJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlNhdmVkIHNlYXJjaGVzIiwiU0FWRURfU0VBUkNIRVNfQ09VTlRfT0ZfMTAiOiJ7c2F2ZWRTZWFyY2hlc0NvdW50fSBvZiAxMCIsIkRFTEVURV9OQU1FIjoiRGVsZXRlIHtuYW1lfSIsIllFUyI6IlllcyIsIk5PIjoiTm8iLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IllvdSBoYXZlIG5vIHNhdmVkIHNlYXJjaGVzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlNhdmVkIHNlYXJjaGVzIiwiU0FWRURfU0VBUkNIRVNfQ09VTlRfT0ZfMTAiOiJ7c2F2ZWRTZWFyY2hlc0NvdW50fSBvZiAxMCIsIkRFTEVURV9OQU1FIjoiRGVsZXRlIHtuYW1lfSIsIllFUyI6IlllcyIsIk5PIjoiTm8iLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IllvdSBoYXZlIG5vIHNhdmVkIHNlYXJjaGVzLiJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlNhdmVkIHNlYXJjaGVzIiwiU0FWRURfU0VBUkNIRVNfQ09VTlRfT0ZfMTAiOiJ7c2F2ZWRTZWFyY2hlc0NvdW50fSBvZiAxMCIsIkRFTEVURV9OQU1FIjoiRGVsZXRlIHtuYW1lfSIsIllFUyI6IlllcyIsIk5PIjoiTm8iLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IllvdSBoYXZlIG5vIHNhdmVkIHNlYXJjaGVzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlNhdmVkIHNlYXJjaGVzIiwiU0FWRURfU0VBUkNIRVNfQ09VTlRfT0ZfMTAiOiJ7c2F2ZWRTZWFyY2hlc0NvdW50fSBvZiAxMCIsIkRFTEVURV9OQU1FIjoiRGVsZXRlIHtuYW1lfSIsIllFUyI6IlllcyIsIk5PIjoiTm8iLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IllvdSBoYXZlIG5vIHNhdmVkIHNlYXJjaGVzLiJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlNhdmVkIHNlYXJjaGVzIiwiU0FWRURfU0VBUkNIRVNfQ09VTlRfT0ZfMTAiOiJ7c2F2ZWRTZWFyY2hlc0NvdW50fSBvZiAxMCIsIkRFTEVURV9OQU1FIjoiRGVsZXRlIHtuYW1lfSIsIllFUyI6IlllcyIsIk5PIjoiTm8iLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IllvdSBoYXZlIG5vIHNhdmVkIHNlYXJjaGVzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlNhdmVkIHNlYXJjaGVzIiwiU0FWRURfU0VBUkNIRVNfQ09VTlRfT0ZfMTAiOiJ7c2F2ZWRTZWFyY2hlc0NvdW50fSBvZiAxMCIsIkRFTEVURV9OQU1FIjoiRGVsZXRlIHtuYW1lfSIsIllFUyI6IlllcyIsIk5PIjoiTm8iLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IllvdSBoYXZlIG5vIHNhdmVkIHNlYXJjaGVzLiJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlNhdmVkIHNlYXJjaGVzIiwiU0FWRURfU0VBUkNIRVNfQ09VTlRfT0ZfMTAiOiJ7c2F2ZWRTZWFyY2hlc0NvdW50fSBvZiAxMCIsIkRFTEVURV9OQU1FIjoiRGVsZXRlIHtuYW1lfSIsIllFUyI6IlllcyIsIk5PIjoiTm8iLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IllvdSBoYXZlIG5vIHNhdmVkIHNlYXJjaGVzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlNhdmVkIHNlYXJjaGVzIiwiU0FWRURfU0VBUkNIRVNfQ09VTlRfT0ZfMTAiOiJ7c2F2ZWRTZWFyY2hlc0NvdW50fSBvZiAxMCIsIkRFTEVURV9OQU1FIjoiRGVsZXRlIHtuYW1lfSIsIllFUyI6IlllcyIsIk5PIjoiTm8iLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IllvdSBoYXZlIG5vIHNhdmVkIHNlYXJjaGVzLiJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlNhdmVkIHNlYXJjaGVzIiwiU0FWRURfU0VBUkNIRVNfQ09VTlRfT0ZfMTAiOiJ7c2F2ZWRTZWFyY2hlc0NvdW50fSBvZiAxMCIsIkRFTEVURV9OQU1FIjoiRGVsZXRlIHtuYW1lfSIsIllFUyI6IlllcyIsIk5PIjoiTm8iLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IllvdSBoYXZlIG5vIHNhdmVkIHNlYXJjaGVzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlNhdmVkIHNlYXJjaGVzIiwiU0FWRURfU0VBUkNIRVNfQ09VTlRfT0ZfMTAiOiJ7c2F2ZWRTZWFyY2hlc0NvdW50fSBvZiAxMCIsIkRFTEVURV9OQU1FIjoiRGVsZXRlIHtuYW1lfSIsIllFUyI6IlllcyIsIk5PIjoiTm8iLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IllvdSBoYXZlIG5vIHNhdmVkIHNlYXJjaGVzLiJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IuC4geC4suC4o+C4hOC5ieC4meC4q+C4suC4l+C4teC5iOC4muC4seC4meC4l+C4tuC4geC5hOC4p+C5iSIsIlNBVkVEX1NFQVJDSEVTX0NPVU5UX09GXzEwIjoie3NhdmVkU2VhcmNoZXNDb3VudH0g4LiI4Liy4LiBIDEwIiwiREVMRVRFX05BTUUiOiLguKXguJoge25hbWV9IiwiWUVTIjoi4LmD4LiK4LmIIiwiTk8iOiLguYTguKHguYjguYPguIrguYgiLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IuC4hOC4uOC4k+C5hOC4oeC5iOC4oeC4teC4geC4suC4o+C4hOC5ieC4meC4q+C4suC4l+C4teC5iOC4muC4seC4meC4l+C4tuC4geC5hOC4p+C5iSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IuC4geC4suC4o+C4hOC5ieC4meC4q+C4suC4l+C4teC5iOC4muC4seC4meC4l+C4tuC4geC5hOC4p+C5iSIsIlNBVkVEX1NFQVJDSEVTX0NPVU5UX09GXzEwIjoie3NhdmVkU2VhcmNoZXNDb3VudH0g4LiI4Liy4LiBIDEwIiwiREVMRVRFX05BTUUiOiLguKXguJoge25hbWV9IiwiWUVTIjoi4LmD4LiK4LmIIiwiTk8iOiLguYTguKHguYjguYPguIrguYgiLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IuC4hOC4uOC4k+C5hOC4oeC5iOC4oeC4teC4geC4suC4o+C4hOC5ieC4meC4q+C4suC4l+C4teC5iOC4muC4seC4meC4l+C4tuC4geC5hOC4p+C5iSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlBlbmNhcmlhbiB0ZXJzaW1wYW4iLCJTQVZFRF9TRUFSQ0hFU19DT1VOVF9PRl8xMCI6IntzYXZlZFNlYXJjaGVzQ291bnR9IGRhcmkgMTAiLCJERUxFVEVfTkFNRSI6IkhhcHVzIHtuYW1lfSIsIllFUyI6IllhIiwiTk8iOiJUaWRhayIsIllPVV9IQVZFX05PX1NBVkVEX1NFQVJDSEVTIjoiVGlkYWsgYWRhIHBlbmNhcmlhbiB0ZXJzaW1wYW4uIn0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlBlbmNhcmlhbiB0ZXJzaW1wYW4iLCJTQVZFRF9TRUFSQ0hFU19DT1VOVF9PRl8xMCI6IntzYXZlZFNlYXJjaGVzQ291bnR9IGRhcmkgMTAiLCJERUxFVEVfTkFNRSI6IkhhcHVzIHtuYW1lfSIsIllFUyI6IllhIiwiTk8iOiJUaWRhayIsIllPVV9IQVZFX05PX1NBVkVEX1NFQVJDSEVTIjoiVGlkYWsgYWRhIHBlbmNhcmlhbiB0ZXJzaW1wYW4uIn0=!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlNhdmVkIHNlYXJjaGVzIiwiU0FWRURfU0VBUkNIRVNfQ09VTlRfT0ZfMTAiOiJ7c2F2ZWRTZWFyY2hlc0NvdW50fSBvZiAxMCIsIkRFTEVURV9OQU1FIjoiRGVsZXRlIHtuYW1lfSIsIllFUyI6IlllcyIsIk5PIjoiTm8iLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IllvdSBoYXZlIG5vIHNhdmVkIHNlYXJjaGVzLiJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlNhdmVkIHNlYXJjaGVzIiwiU0FWRURfU0VBUkNIRVNfQ09VTlRfT0ZfMTAiOiJ7c2F2ZWRTZWFyY2hlc0NvdW50fSBvZiAxMCIsIkRFTEVURV9OQU1FIjoiRGVsZXRlIHtuYW1lfSIsIllFUyI6IlllcyIsIk5PIjoiTm8iLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IllvdSBoYXZlIG5vIHNhdmVkIHNlYXJjaGVzLiJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlvFoMSDxIPEg+G5veG6veG6veG6vcaMIMWh4bq94bq94bq9xIPEg8SDxZnDp+G4qeG6veG6veG6vcWhXSIsIlNBVkVEX1NFQVJDSEVTX0NPVU5UX09GXzEwIjoiW3tzYXZlZFNlYXJjaGVzQ291bnR9IMO2w7bDtsO2xpIgMTBdIiwiREVMRVRFX05BTUUiOiJb4biK4bq94bq9xprhur3hur3hua/hur3hur0ge25hbWV9XSIsIllFUyI6IlvDneG6veG6veG6vcWhXSIsIk5PIjoiW8ORw7bDtl0iLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IlvDncO2w7bDtseax5rHmiDhuKnEg8SDxIPhub3hur3hur3hur0g4LiB4Li14LmJw7bDtsO2IMWhxIPEg8SD4bm94bq94bq94bq9xowgxaHhur3hur3hur3Eg8SDxIPFmcOn4bip4bq94bq94bq9xaEuXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTQVZFRF9TRUFSQ0hFUyI6IlvFoMSDxIPEg+G5veG6veG6veG6vcaMIMWh4bq94bq94bq9xIPEg8SDxZnDp+G4qeG6veG6veG6vcWhXSIsIlNBVkVEX1NFQVJDSEVTX0NPVU5UX09GXzEwIjoiW3tzYXZlZFNlYXJjaGVzQ291bnR9IMO2w7bDtsO2xpIgMTBdIiwiREVMRVRFX05BTUUiOiJb4biK4bq94bq9xprhur3hur3hua/hur3hur0ge25hbWV9XSIsIllFUyI6IlvDneG6veG6veG6vcWhXSIsIk5PIjoiW8ORw7bDtl0iLCJZT1VfSEFWRV9OT19TQVZFRF9TRUFSQ0hFUyI6IlvDncO2w7bDtseax5rHmiDhuKnEg8SDxIPhub3hur3hur3hur0g4LiB4Li14LmJw7bDtsO2IMWhxIPEg8SD4bm94bq94bq94bq9xowgxaHhur3hur3hur3Eg8SDxIPFmcOn4bip4bq94bq94bq9xaEuXSJ9!"
        )
      )
      });
  
      export { translations as default };
    
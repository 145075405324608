import { Box } from 'braid-design-system';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import { CompatRoute, useLocation } from 'react-router-dom-v5-compat';

import queryStringService, {
  parsers,
} from 'src/services/location/queryStringService';
import { Tags, type RouteDefinition } from 'src/types';

import { IntercomConfiguration } from '../../../../setup/IntercomConfiguration';
import { selectors as routerSelectors } from '../../../../store/router';
import { selectors as userSelectors } from '../../../../store/user';
import JobDataContainer from '../../containers/JobDataContainer/JobDataContainer';
import MetadataContainer from '../../containers/MetadataContainer/MetadataContainer';
import SearchIdContainer from '../../containers/SearchIdContainer/SearchIdContainer';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import StickyBanners from '../StickyBanners/StickyBanners';

interface Props {
  routes: {
    unrestricted: RouteDefinition[];
    restricted: RouteDefinition[];
    recommendedCandidates: RouteDefinition[];
  };
}

export const TalentSearchHeaderRoute = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const isNotRootOrErrorRoute = useSelector(
    routerSelectors.isValidRouteSelector,
  );

  return (
    <Box display="flex" flexDirection="column" style={{ minHeight: '100vh' }}>
      <Header />

      <StickyBanners />

      <Box flexGrow={1}>{children}</Box>

      {isNotRootOrErrorRoute && <Footer />}
    </Box>
  );
};

export function AppView({ routes }: Props) {
  const hasInitialUserData = useSelector(
    userSelectors.getInitialDataStateSelector,
  );

  const { pathname, search } = useLocation();
  // Ideally we should use `URLSearchParams` but webpack/Node fights us for now
  const { pageNumber } = useMemo(
    () =>
      queryStringService.parseParams(search, {
        pageNumber: { type: parsers.number, defaultValue: null },
      }),
    [search],
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, pageNumber]);

  return (
    <ErrorBoundary>
      <Switch>
        {routes.unrestricted.map(({ key, component: Component, ...route }) => (
          <CompatRoute
            key={key}
            path={route.path}
            exact
            component={() => <Component />}
          />
        ))}
        {routes.recommendedCandidates.map(({ key, component, ...route }) => (
          // @ts-expect-error Type 'unknown' is not a valid JSX element type.
          <PrivateRoute
            key={key}
            path={route.path}
            component={component}
            exact
          />
        ))}
        {routes.restricted.map(({ key, component: Component, ...route }) => (
          // @ts-expect-error Type 'unknown' is not a valid JSX element type.
          <PrivateRoute
            key={key}
            {...route}
            render={() => (
              <TalentSearchHeaderRoute>
                <Component />
              </TalentSearchHeaderRoute>
            )}
          />
        ))}
      </Switch>

      {/* Data services and configs */}
      {hasInitialUserData ? (
        <Box position="absolute">
          <JobDataContainer />
          <MetadataContainer />
          <SearchIdContainer />
          <IntercomConfiguration />
          <Box component="span" data={{ cy: Tags.Data.AppReadyIdentifier }} />
        </Box>
      ) : null}
    </ErrorBoundary>
  );
}

import { Strong, Box } from 'braid-design-system';
import { useRef, useState, useEffect } from 'react';
import Transition from 'react-transition-group/Transition';

import { Tags } from '../../../types';
import type { AnimationState } from '../Animate/types';

import { useNumberFormatter } from './useFormatNumber';

import * as styles from './Amount.css';

export const ANIMATION_TIMEOUT_MS = 200;

interface Props {
  value: number | null | undefined;
}

export const Amount = ({ value }: Props) => {
  const prevValue = useRef(value);
  const nodeRef = useRef(null);
  const [isAnimating, setAnimate] = useState(false);

  const { formatNumber } = useNumberFormatter();
  const formattedValue = formatNumber(value);
  const [displayedFormattedValue, setDisplayedFormattedValue] =
    useState(formattedValue);

  useEffect(() => {
    let animation: any;
    if (prevValue.current !== value) {
      setAnimate(true);
      prevValue.current = value;

      animation = setTimeout(() => {
        setDisplayedFormattedValue(formattedValue);
        setAnimate(false);
      }, ANIMATION_TIMEOUT_MS);
    }

    return () => {
      if (animation) {
        clearTimeout(animation);
      }
    };
  }, [formattedValue, value]);

  return (
    <Transition nodeRef={nodeRef} in={isAnimating} timeout={500}>
      {(state: AnimationState) => (
        <Box
          display="inlineBlock"
          className={styles[state]}
          data={{ cy: Tags.Data.CreditsBalance }}
        >
          <Strong data={{ testid: 'formatted-value' }}>
            {displayedFormattedValue}
          </Strong>
        </Box>
      )}
    </Transition>
  );
};

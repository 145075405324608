const SEEK_ID_LOCAL_STORAGE_KEY = 'adv-account-selection-seek-id';

const setId = (key: string, value: string | number | null) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(key, `${value}`);
  }
};

export const setSeekIdForLocalDev = (value: string | null) =>
  setId(SEEK_ID_LOCAL_STORAGE_KEY, value);

import * as Apollo from '@apollo/client';
import type { DocumentNode } from 'graphql';

import type * as Types from '../../../types/generated';

const defaultOptions = {} as const;
export type ContractBalanceFieldsFragment = {
  __typename: 'TalentSearchContractBalance';
  initialBalance: number;
  currentBalance: number;
};

export type GetTalentSearchContractBalanceQueryVariables = Types.Exact<{
  jobId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type GetTalentSearchContractBalanceQuery = {
  talentSearchUserContext: {
    id: string;
    contractBalance:
      | { __typename: 'ResponseError'; error: Types.ResponseErrorReason }
      | {
          __typename: 'TalentSearchContractBalance';
          initialBalance: number;
          currentBalance: number;
        }
      | { __typename: 'TalentSearchUnavailableContractBalance' };
  };
};

export const ContractBalanceFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContractBalanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TalentSearchContractBalance' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBalance' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const GetTalentSearchContractBalanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTalentSearchContractBalance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'talentSearchUserContext' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'jobId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'jobId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contractBalance' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'TalentSearchContractBalance',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'ContractBalanceFields',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: {
                            kind: 'Name',
                            value: 'TalentSearchUnavailableContractBalance',
                          },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '__typename' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ResponseError' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '__typename' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'error' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContractBalanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TalentSearchContractBalance' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBalance' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetTalentSearchContractBalanceQuery__
 *
 * To run a query within a React component, call `useGetTalentSearchContractBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentSearchContractBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentSearchContractBalanceQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetTalentSearchContractBalanceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTalentSearchContractBalanceQuery,
    GetTalentSearchContractBalanceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTalentSearchContractBalanceQuery,
    GetTalentSearchContractBalanceQueryVariables
  >(GetTalentSearchContractBalanceDocument, options);
}
export function useGetTalentSearchContractBalanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTalentSearchContractBalanceQuery,
    GetTalentSearchContractBalanceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTalentSearchContractBalanceQuery,
    GetTalentSearchContractBalanceQueryVariables
  >(GetTalentSearchContractBalanceDocument, options);
}
export function useGetTalentSearchContractBalanceSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetTalentSearchContractBalanceQuery,
        GetTalentSearchContractBalanceQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetTalentSearchContractBalanceQuery,
    GetTalentSearchContractBalanceQueryVariables
  >(GetTalentSearchContractBalanceDocument, options);
}
export type GetTalentSearchContractBalanceQueryHookResult = ReturnType<
  typeof useGetTalentSearchContractBalanceQuery
>;
export type GetTalentSearchContractBalanceLazyQueryHookResult = ReturnType<
  typeof useGetTalentSearchContractBalanceLazyQuery
>;
export type GetTalentSearchContractBalanceSuspenseQueryHookResult = ReturnType<
  typeof useGetTalentSearchContractBalanceSuspenseQuery
>;
export type GetTalentSearchContractBalanceQueryResult = Apollo.QueryResult<
  GetTalentSearchContractBalanceQuery,
  GetTalentSearchContractBalanceQueryVariables
>;

import { useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';

import useAppConfig from 'src/hooks/useAppConfig';
import { useGetTalentSearchJobLazyQuery } from 'src/services/data/jobs/GetTalentSearchJob.generated';
import { wrapLazyQuery } from 'src/services/utils/wrapQuery';

import {
  selectors as jobSelectors,
  actionCreators as jobActionCreators,
} from '../../../../store/job';
import { selectors as routerSelectors } from '../../../../store/router';
import type { RootState, TalentSearchJobV2 } from '../../../../types';

interface Props {
  activeJob: TalentSearchJobV2 | null;
  jobId: number | null;
  updateJobDetails: typeof jobActionCreators.updateJobDetails;
  clearJobDetails: typeof jobActionCreators.clearJobDetails;
}

export function JobDataContainer({
  activeJob,
  jobId,
  updateJobDetails,
  clearJobDetails,
}: Props) {
  const [getTalentSearchJobLazyQuery] = wrapLazyQuery(
    useGetTalentSearchJobLazyQuery,
  )();

  const { LANGUAGE } = useAppConfig();
  const prevJobId = useRef<number | null>(null);
  const syncActiveJob = useCallback(
    async (nextJobId: number | null) => {
      if (nextJobId) {
        if (Number(activeJob?.id) !== nextJobId) {
          const jobDetails = await getTalentSearchJobLazyQuery({
            variables: {
              id: nextJobId,
              language: LANGUAGE,
            },
          });

          if (!jobDetails.error && jobDetails.data?.talentSearchJob != null) {
            updateJobDetails(jobDetails.data.talentSearchJob);
          }
        }
      } else if (activeJob?.id) {
        clearJobDetails();
      }
    },
    [
      activeJob?.id,
      getTalentSearchJobLazyQuery,
      LANGUAGE,
      updateJobDetails,
      clearJobDetails,
    ],
  );

  useEffect(() => {
    if (prevJobId.current !== jobId) {
      prevJobId.current = jobId;
      syncActiveJob(jobId);
    }
  }, [jobId, syncActiveJob]);

  return null;
}

const mapStateToProps = (state: RootState) => ({
  activeJob: jobSelectors.getActiveJobSelector(state),
  jobId: routerSelectors.getActiveJobIdSelector(state),
});

const mapDispatchToProps = {
  updateJobDetails: jobActionCreators.updateJobDetails,
  clearJobDetails: jobActionCreators.clearJobDetails,
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, mapDispatchToProps)(JobDataContainer);

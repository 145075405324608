import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom-v5-compat';

import errorService from '../../services/error';
import queryStringService from '../../services/location/queryStringService';
import type { AxiosOrGraphQLError, ErrorTypes } from '../../types';
import useAppConfig from '../useAppConfig';
import type { useRoutes } from '../useRoutes';

export interface Router {
  /**
   * Returns useLocation hook pathname and search in original format
   */
  location: ReturnType<typeof useLocation>;
  /**
   * Redirects (with full page reload) to "/"
   */
  redirectToStartPage: () => void;
  /**
   * Navigates to /error/:errorType route
   * @param {ErrorTypes} errorType - Navigates to custom error page
   */
  navigateToError: (
    routes: ReturnType<typeof useRoutes>['routes'],
    errorType: ErrorTypes,
    context?: {
      err?: AxiosOrGraphQLError;
      jobId?: number;
      advertiserId?: number;
    },
  ) => void;

  /**
   * Set the key/s to the value/s. If value is null, delete the key.
   * @param {object} query - Object containing next params. Null params will be removed from the query string.
   */
  replaceSearchParams: <T extends Record<string, unknown>>(query: T) => void;
  /**
   * Update the location.href with new path
   * @param path new path to change
   * @returns void
   */
  forwardToPath: (path: string) => void;
}

export function getNextSearch(
  _search: string,
  query: Record<string, unknown>,
): string {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, nextSearch] = queryStringService.updateParams(
    location.search,
    query,
  );
  return `?${nextSearch}`;
}

function useRouter(): Router {
  const location = useLocation();
  const navigate = useNavigate();

  const { ROUTER_BASE_NAME } = useAppConfig();

  return {
    location,
    replaceSearchParams: (query) => {
      const searchParams = new URLSearchParams(location.search);
      Object.keys(query).forEach((key) => {
        const value = query[key];
        if (value === null) {
          searchParams.delete(key);
        } else {
          searchParams.set(key, value as string);
        }
      });

      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    },
    redirectToStartPage: () => {
      // TODO: does this work now?
      window.location.replace(ROUTER_BASE_NAME);
    },
    navigateToError: (routes, errorType, context = {}) => {
      const { err, ...restContext } = context;

      const details = err ? errorService.getDetailsFromError(err) : null;
      const code = details?.opaqueErrorId
        ? details.opaqueErrorId
        : errorService.mapAPIErrorCodeToFriendlyCode(details?.apiErrorCode);

      const url = routes.error({
        errorType,
        code,
        advertiserId: restContext.advertiserId,
        jobId: restContext.jobId,
      });

      navigate(url);
    },
    forwardToPath: (path: string) => {
      window.location.href = path;
    },
  };
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default useRouter;

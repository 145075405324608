import { useHirerAuth } from '@seek/hirer-auth-react';
import { useTranslations } from '@vocab/react';
import { Box, TextLinkButton, Text } from 'braid-design-system';

import translations from './.vocab';

export const SignOut = () => {
  const { logout } = useHirerAuth();
  const { t } = useTranslations(translations);

  const handleSignOutClick = async () => {
    logout();
  };

  return (
    <Box display="flex" alignItems="center" padding="medium">
      <Text>
        <TextLinkButton onClick={handleSignOutClick}>
          {t('SIGN_OUT')}
        </TextLinkButton>
      </Text>
    </Box>
  );
};

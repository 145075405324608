import * as Apollo from '@apollo/client';
import type { DocumentNode } from 'graphql';

import type * as Types from '../../../../../../types/generated';

const defaultOptions = {} as const;
export type GetSwitchAccountsHrefQueryVariables = Types.Exact<
  Record<string, never>
>;

export type GetSwitchAccountsHrefQuery = {
  headerFooterContext?: {
    advertiser?: { switchAccountsHref?: string | null } | null;
  } | null;
};

export const GetSwitchAccountsHrefDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSwitchAccountsHref' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headerFooterContext' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'advertiser' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'switchAccountsHref' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetSwitchAccountsHrefQuery__
 *
 * To run a query within a React component, call `useGetSwitchAccountsHrefQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSwitchAccountsHrefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSwitchAccountsHrefQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSwitchAccountsHrefQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSwitchAccountsHrefQuery,
    GetSwitchAccountsHrefQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSwitchAccountsHrefQuery,
    GetSwitchAccountsHrefQueryVariables
  >(GetSwitchAccountsHrefDocument, options);
}
export function useGetSwitchAccountsHrefLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSwitchAccountsHrefQuery,
    GetSwitchAccountsHrefQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSwitchAccountsHrefQuery,
    GetSwitchAccountsHrefQueryVariables
  >(GetSwitchAccountsHrefDocument, options);
}
export function useGetSwitchAccountsHrefSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetSwitchAccountsHrefQuery,
        GetSwitchAccountsHrefQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetSwitchAccountsHrefQuery,
    GetSwitchAccountsHrefQueryVariables
  >(GetSwitchAccountsHrefDocument, options);
}
export type GetSwitchAccountsHrefQueryHookResult = ReturnType<
  typeof useGetSwitchAccountsHrefQuery
>;
export type GetSwitchAccountsHrefLazyQueryHookResult = ReturnType<
  typeof useGetSwitchAccountsHrefLazyQuery
>;
export type GetSwitchAccountsHrefSuspenseQueryHookResult = ReturnType<
  typeof useGetSwitchAccountsHrefSuspenseQuery
>;
export type GetSwitchAccountsHrefQueryResult = Apollo.QueryResult<
  GetSwitchAccountsHrefQuery,
  GetSwitchAccountsHrefQueryVariables
>;

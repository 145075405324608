import { InMemoryCache } from '@apollo/client';
import { HirerApolloProvider } from '@seek/hirer-graphql-react';

import useAppConfig from 'src/hooks/useAppConfig';
import useCustomHeaders from 'src/hooks/useCustomHeaders';
import useDatadogRum from 'src/hooks/useDatadogRum';

import { mapGraphQLErrorLogger, mapRequestErrorLogger } from './errorMappers';

interface Props {
  children: React.ReactNode;
}

export const HirerGraphQLProvider = ({ children }: Props) => {
  const {
    HIRER_GRAPH_URL: uri,
    APP_NAME: appName,
    ENVIRONMENT: environment,
    SITE: site,
    APP_VERSION: siteVersion,
  } = useAppConfig();
  const getHeaders = useCustomHeaders();

  const datadogRumContext = useDatadogRum();

  const hirerApolloProps: React.ComponentProps<typeof HirerApolloProvider> = {
    appName,
    site,
    logError: mapRequestErrorLogger(datadogRumContext),
    logGraphQLError: mapGraphQLErrorLogger(datadogRumContext),
    uri,
    environment,
    siteVersion,
    cache: new InMemoryCache({
      possibleTypes: {
        TalentSearchJobBalance: [
          'TalentSearchJobCreditsBalance',
          'TalentSearchCandidateJobInvitationsBalance',
          'TalentSearchUnavailableJobBalance',
        ],
      },
      typePolicies: {
        TalentSearchProfileResultV2: {
          // profiles are identified by profileId
          keyFields: ['profileId'],
        },
      },
    }),
    getHeaders,
    batchOptions: {
      batchMax: 20,
      batchInterval: 20,
    },
  };

  return (
    <HirerApolloProvider {...hirerApolloProps}>{children}</HirerApolloProvider>
  );
};

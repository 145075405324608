import { useTranslations } from '@vocab/react';
import { IconNewWindow } from 'braid-design-system';

import { Amount } from 'src/common/UI/Amount/Amount';
import { useBalances } from 'src/hooks/useBalances/useBalances';
import useJobId from 'src/hooks/useJobId';

import translations from './.vocab';

// TODO Address Post-Tilt unit test coverage
export const ContractBalance = () => {
  const { t } = useTranslations(translations);
  const jobId = useJobId();

  const { contractBalance } = useBalances({ jobId });

  return (
    contractBalance?.__typename === 'TalentSearchContractBalance' && (
      <>
        {t('CREDITS')} <Amount value={contractBalance.currentBalance} />{' '}
        <IconNewWindow />
      </>
    )
  );
};

// Default exports can be ignored for this file. sku templates provide default exports as standard here
/* eslint-disable import/no-default-export */

import { setSeekId } from '@seek/adv-account-selection';
import { MelwaysProvider } from '@seek/melways-react';
import { hydrateRoot } from 'react-dom/client';
import { Provider as ReduxStoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';

import App from './common/App/App';
import { createAppConfig } from './config/createAppConfig';
import { AppConfigProvider } from './hooks/useAppConfig';
import { HubbleClientProvider } from './hooks/useHubble/HubbleClientProvider';
import { RouterProvider } from './hooks/useRouter';
import { RoutesProvider } from './hooks/useRoutes';
import routes from './routes';
import createStore from './store';
import type { ClientContext } from './types';

const setDevSeekIdFromUrl = () => {
  const urlParams = new URLSearchParams(document.location.search);
  const advertiserId = urlParams.get('advertiserId');

  if (typeof advertiserId === 'string') {
    // eslint-disable-next-line no-console
    console.log('Setting advertiser ID for dev:', advertiserId);
    setSeekId(advertiserId);
  }
};

export default (clientContext: ClientContext) => {
  //  Builds on the config by substituting values
  //  that are only available from the client side
  const config = createAppConfig(clientContext);
  const store = createStore(config);

  if (config.ENVIRONMENT === 'development') {
    setDevSeekIdFromUrl();
  }

  hydrateRoot(
    document.getElementById('app')!,
    <ReduxStoreProvider store={store}>
      <BrowserRouter>
        <CompatRouter>
          <RouterProvider
            routes={[
              ...routes.unrestricted,
              ...routes.recommendedCandidates,
              ...routes.restricted,
            ]}
          >
            <AppConfigProvider config={config}>
              <MelwaysProvider.Client
                site={config.SITE}
                staging={config.ENVIRONMENT !== 'production'}
              >
                <HubbleClientProvider>
                  <RoutesProvider
                    environment={config.ENVIRONMENT}
                    locale={config.LOCALE}
                    site={config.SITE}
                  >
                    <App routes={routes} />
                  </RoutesProvider>
                </HubbleClientProvider>
              </MelwaysProvider.Client>
            </AppConfigProvider>
          </RouterProvider>
        </CompatRouter>
      </BrowserRouter>
    </ReduxStoreProvider>,
  );
};

import * as actions from './actions';

export const updateSearchId = (searchId: string) => ({
  type: actions.UPDATE_SEARCH_ID,
  value: {
    searchId,
  },
});

export const updateSimilarCandidatesSearchIdInProfile = (
  similarCandidatesSearchIdInProfile: string,
) => ({
  type: actions.UPDATE_SIMILAR_CANDIDATES_SEARCH_ID_IN_PROFILE,
  value: {
    similarCandidatesSearchIdInProfile,
  },
});

export const updateActiveSimilarCandidatesSearchId = (
  activeSimilarCandidatesId: string,
) => ({
  type: actions.UPDATE_ACTIVE_SIMILAR_CANDIDATES_ID,
  value: {
    activeSimilarCandidatesId,
  },
});

import type { RootState } from '../../types';
import { getSeedProfileIdFromQueryStringSelector } from '../router/selectors';

export const getSearchIdSelector = ({ search }: RootState): string =>
  search.searchId;

export const getSimilarCandidatesSearchIdIfSeedProfileIdExistsSelector = (
  state: RootState,
): string | null => {
  const seedProfileIdFromQueryString =
    getSeedProfileIdFromQueryStringSelector(state);
  return seedProfileIdFromQueryString // todo check if we can get rid of the seedProfileIdFromQueryString checking
    ? state.search.similarCandidatesSearchIdInProfile
    : null;
};

export const getActiveSimilarCandidatesId = (
  state: RootState,
): string | undefined => state.search.activeSimilarCandidatesId;

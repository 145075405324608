import { useHirerAuth } from '@seek/hirer-auth-react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RouteLoader } from 'src/common/App/components/RouteLoader/RouteLoader';
import useDatadogRum from 'src/hooks/useDatadogRum';
import { getCaughtError } from 'src/services/utils/getCaughtError';

import { selectors as userSelectors } from '../../../../../store/user';

function withAuthentication<P extends JSX.IntrinsicAttributes>(
  WrappedRoute: React.ComponentType<P>,
) {
  return (props: P) => {
    const dispatch = useDispatch();
    const { getToken } = useHirerAuth();

    const isAuthenticated = useSelector(
      userSelectors.isUserAuthenticatedSelector,
    );

    const hasMounted = useRef<boolean>(false);
    const [hasAuth, setHasAuth] = useState<boolean>(isAuthenticated);
    const { logError } = useDatadogRum();

    useEffect(() => {
      (async () => {
        if (hasMounted.current || hasAuth) {
          return;
        }

        let accessToken;

        try {
          accessToken = await getToken();
        } catch (err) {
          logError('Authenticating user failed.', {
            error: getCaughtError(err),
          });
          //  Exceptions handled in AuthProvider
        }

        //  If either of these return NULL
        //  the user will automatically be redirected
        //  to authenticate.seek.com
        if (!accessToken) {
          return;
        }

        setHasAuth(true);
        hasMounted.current = true;
      })();
    }, [dispatch, getToken, hasAuth, isAuthenticated, logError]);

    if (!hasAuth) {
      return <RouteLoader />;
    }

    return <WrappedRoute {...(props as P)} />;
  };
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default withAuthentication;

import { Box, Inline } from 'braid-design-system';
import type { ComponentProps } from 'react';
import { matchPath } from 'react-router-dom';

import type { useRoutes } from 'src/hooks/useRoutes';
import * as ACL from 'src/types/acl';

import { ContractBalance } from '../ContractBalance/ContractBalance';

import type { GetPageLinkOptions, Option, PageLink } from './types';

type SceneLinkConfig = Record<ACL.SceneLink, Option>;
type ReactNodeNoStrings = ComponentProps<typeof Inline>['children'];
type BoxChildren = ComponentProps<typeof Box>['children'];

const createSceneLinkConfig = (
  routes: ReturnType<typeof useRoutes>['routes'],
): SceneLinkConfig => ({
  [ACL.SCENE_LINK.TALENT_SEARCH]: {
    getLinkProps: ({ t }) => ({
      isActive: (_match, { pathname } = {}) =>
        Boolean(matchPath(pathname, routes.searchUncoupled())?.isExact) ||
        Boolean(matchPath(pathname, routes.searchResultsUncoupled())?.isExact),
      href: routes.searchUncoupled(),
      linkText: t('TALENT_SEARCH'),
      children: <>{t('TALENT_SEARCH')}</>,
    }),
  },
  [ACL.SCENE_LINK.TALENT_SEARCH_BY_JOB]: {
    getLinkProps: ({ t }) => ({
      href: routes.searchCoupled(),
      linkText: t('TALENT_SEARCH_BY_JOB', {
        Inline: (children: ReactNodeNoStrings) => (
          <Inline space="xxsmall">{children}</Inline>
        ),
        Box: (children: BoxChildren) => <Box>{children}</Box>,
      }),
      children: (
        <>
          {t('TALENT_SEARCH_BY_JOB', {
            br: () => <br />,
            Inline: (children: ReactNodeNoStrings) => (
              <Inline space="xxsmall">{children}</Inline>
            ),
            Box: (children: BoxChildren) => <Box>{children}</Box>,
          })}
        </>
      ),
    }),
  },
  [ACL.SCENE_LINK.TALENT_POOLING]: {
    getLinkProps: ({ t }) => ({
      href: routes.pooling(),
      linkText: t('TALENT_POOLS'),
      children: <>{t('TALENT_POOLS')}</>,
    }),
  },
  [ACL.SCENE_LINK.CREDITS_DASHBOARD]: {
    getLinkProps: ({ advertisersCreditDashboardUrl }) => ({
      href: advertisersCreditDashboardUrl,
      hasCreditsMetricsEvent: true,
      hasCredits: true,
      isExternal: true,
      children: <ContractBalance />,
    }),
  },
});

export const getPageLinks = (
  links: ACL.SceneLink[],
  { creditDashboardUrl, advertiserId, jobId, t }: GetPageLinkOptions,
  routes: ReturnType<typeof useRoutes>['routes'],
): PageLink[] => {
  const pageLinks: PageLink[] = [];
  for (const sceneLinkKey of links) {
    const { getLinkProps } = createSceneLinkConfig(routes)[sceneLinkKey];
    const advertisersCreditDashboardUrl = `${creditDashboardUrl}/${advertiserId}`;
    pageLinks.push(
      getLinkProps({
        advertisersCreditDashboardUrl,
        jobId,
        t,
      }),
    );
  }

  return pageLinks;
};

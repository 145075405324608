import { Hubble, HubbleProvider } from '@seek/hubble';
import { useMelwaysInfo } from '@seek/melways-react';

import useAppConfig from '../useAppConfig';

interface Props {
  children: React.ReactNode;
}

export const HubbleClientProvider = ({ children }: Props) => {
  const {
    APP_NAME: appName,
    APP_VERSION: appVersion,
    COUNTRY_CODE: country,
  } = useAppConfig();

  const { brand } = useMelwaysInfo();
  const hubble = new Hubble({
    experience: 'hirer',
    appName,
    appVersion,
    country,
    brand,
    isProduction: false,
  });

  return <HubbleProvider hubble={hubble}>{children}</HubbleProvider>;
};

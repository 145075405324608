import { getSeekId } from '@seek/adv-account-selection';
import type {
  AuthenticationLevel,
  HirerAuthProvider,
} from '@seek/hirer-auth-react';
import type { ComponentProps } from 'react';

import { Environment } from 'src/types';

import { setSeekIdForLocalDev } from './utils';

interface Options {
  environment: Environment;
}

// @ts-expect-error Element implicitly has an 'any' type because type 'typeof globalThis' has no index signature.
const isEnvMocked = () => global.USE_STAGING_ENV_FOR_LOCAL !== 'true';

const FALLBACK_PTS_ADVERTISER_ID = '62188414';

export const getHirerAuthProviderProps = (
  options: Options,
): Omit<ComponentProps<typeof HirerAuthProvider>, 'children'> => {
  const authenticationLevel: AuthenticationLevel =
    getAuthenticationLevel(options);

  return {
    authenticationLevel,
    ...(authenticationLevel === 'Mocked' && {
      mockOptions: getMockOptions(),
    }),
  };
};

const getAuthenticationLevel = (
  options: Options,
): Extract<AuthenticationLevel, 'Mocked' | 'Hirer'> =>
  options.environment === Environment.Development && isEnvMocked()
    ? 'Mocked'
    : 'Hirer';

const getMockOptions = (): ComponentProps<
  typeof HirerAuthProvider
>['mockOptions'] => {
  if (typeof window === 'undefined') {
    return;
  }

  // If a hirer account id has been set in local storage already, prefer that over the default.
  // However, if a value is supplied from the querystring, we should prefer that over the local storage value.
  const searchParams = new URLSearchParams(
    window.location.search.toLowerCase(),
  );

  const querystringAdvertiserId = searchParams.get('advertiserid');
  const previouslySetAdvertiserId = getSeekId();

  const advertiserId =
    querystringAdvertiserId ??
    previouslySetAdvertiserId ??
    FALLBACK_PTS_ADVERTISER_ID;

  setSeekIdForLocalDev(advertiserId);

  return {
    authenticated: true,
    identity: {
      advertiserId,
    },
  };
};

export type CredentialListFields = Array<{
  label: string;
  values: Array<{ text: string; isVerified?: boolean }>;
}>;

export const findVerifiedName = (
  digitalIdFields: Array<{ label: string; value: string }> | undefined,
) =>
  digitalIdFields?.find((item) => item.label.toLowerCase() === 'name')?.value;

export const getVerifiedIdentity = (
  digitalIdFields: Array<{ label: string; value: string }> | undefined,
  ethicalHiring: boolean,
  hiddenText: string,
): CredentialListFields | undefined =>
  digitalIdFields?.map((item: { label: string; value: string }) => ({
    label: item.label,
    values: [
      {
        text:
          item.label.toLowerCase() === 'name' && ethicalHiring
            ? hiddenText
            : item.value,
        isVerified: true,
      },
    ],
  }));

import { useMemo } from 'react';

import useAppConfig from 'src/hooks/useAppConfig';

export const useNumberFormatter = () => {
  const { LOCALE } = useAppConfig();

  const numberFormatter = useMemo(
    () => new Intl.NumberFormat(LOCALE),
    [LOCALE],
  );

  const formatNumber = (input: number | null | undefined): string => {
    if (input === null || typeof input === 'undefined') {
      return '';
    }
    return numberFormatter.format(input);
  };

  return {
    formatNumber,
  };
};

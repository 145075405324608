import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { matchPath } from 'react-router-dom';
import { useLocation } from 'react-router-dom-v5-compat';

import { actionCreators as routerActionCreators } from '../../store/router';
import { RoutePaths, type RouteDefinition } from '../../types';

export interface Props {
  routes: RouteDefinition[];
  children: React.ReactNode;
}

//  Takes the jobId from the path if it exists in the format
//  /job/:jobId, or takes the ?jobId=123 as a fallback
function getActiveJobId(params?: { jobId?: number }, search?: string) {
  if (params?.jobId) {
    return Number(params.jobId);
  }

  const searchParams = new URLSearchParams(search);
  const jobId = searchParams.get('jobId');

  return jobId ? Number(jobId) : null;
}

function getMatchingPath(locationPath: string, routes: RouteDefinition[]) {
  return (
    routes.find(({ path }) => {
      const match = matchPath(locationPath, { path, exact: true });
      return match;
    })?.key || ''
  );
}

const RouterProvider: React.FC<Props> = ({ routes, children }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const key = getMatchingPath(location.pathname, routes);

    const locationMatch = matchPath(location.pathname, {
      path: [...Object.values(RoutePaths)],
      exact: true,
    });

    const jobIdFromEitherParamsOrURL = getActiveJobId(
      locationMatch?.params,
      location.search,
    );

    dispatch(
      routerActionCreators.updateActiveRoute({
        key,
        pathname: location.pathname,
        search: location.search,
        params: locationMatch?.params || {},
        jobId: jobIdFromEitherParamsOrURL,
      }),
    );
  }, [location, dispatch, routes]);

  return <>{children}</>;
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default RouterProvider;

import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import type { AppConfig } from '../types';

import createInitialState from './createInitialState';
import jobReducer from './job/reducer';
import marketsReducer from './markets/reducer';
import metadataReducer from './metadata/reducer';
import poolsReducer from './pools/reducer';
import profilesReducer from './profiles/reducer';
import routerReducer from './router/reducer';
import savedSearchesReducer from './savedSearches/reducer';
import searchesReducer from './searches/reducer';
import settingsReducer from './settings/reducer';
import userReducer from './user/reducer';

export const reducers = {
  user: userReducer,
  pools: poolsReducer,
  savedSearches: savedSearchesReducer,
  search: searchesReducer,
  router: routerReducer,
  settings: settingsReducer,
  metadata: metadataReducer,
  markets: marketsReducer,
  job: jobReducer,
  profiles: profilesReducer,
};

export const rootReducer = combineReducers(reducers);

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default (appConfig: AppConfig) => {
  const composeEnhancers =
    (appConfig.ENVIRONMENT !== 'production' &&
      typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;
  return createStore(
    rootReducer,
    createInitialState(appConfig),
    composeEnhancers(applyMiddleware(thunk)),
  );
};

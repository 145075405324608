import * as Apollo from '@apollo/client';
import type { DocumentNode } from 'graphql';

import type * as Types from '../../../types/generated';

const defaultOptions = {} as const;
export type GetTalentSearchJobBalanceQueryVariables = Types.Exact<{
  jobId: Types.Scalars['ID']['input'];
}>;

export type GetTalentSearchJobBalanceQuery = {
  talentSearchJob?: {
    id: string;
    expiryDate: string;
    balanceV2:
      | { __typename: 'ResponseError'; error: Types.ResponseErrorReason }
      | {
          __typename: 'TalentSearchCandidateJobInvitationsBalance';
          currentBalance: number;
          initialBalance: number;
        }
      | {
          __typename: 'TalentSearchJobCreditsBalance';
          currentBalance: number;
          initialBalance: number;
        }
      | { __typename: 'TalentSearchUnavailableJobBalance' };
  } | null;
};

export const GetTalentSearchJobBalanceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTalentSearchJobBalance' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'talentSearchJob' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'jobId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'jobId' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'balanceV2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'JobBalanceFields' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobBalanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TalentSearchJobBalance' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TalentSearchJobCreditsBalance' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreditsBalanceFields' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'TalentSearchCandidateJobInvitationsBalance',
              },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InvitationsBalanceFields' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'TalentSearchUnavailableJobBalance',
              },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResponseError' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditsBalanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TalentSearchJobCreditsBalance' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialBalance' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvitationsBalanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'TalentSearchCandidateJobInvitationsBalance',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialBalance' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetTalentSearchJobBalanceQuery__
 *
 * To run a query within a React component, call `useGetTalentSearchJobBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentSearchJobBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentSearchJobBalanceQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetTalentSearchJobBalanceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTalentSearchJobBalanceQuery,
    GetTalentSearchJobBalanceQueryVariables
  > &
    (
      | { variables: GetTalentSearchJobBalanceQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTalentSearchJobBalanceQuery,
    GetTalentSearchJobBalanceQueryVariables
  >(GetTalentSearchJobBalanceDocument, options);
}
export function useGetTalentSearchJobBalanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTalentSearchJobBalanceQuery,
    GetTalentSearchJobBalanceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTalentSearchJobBalanceQuery,
    GetTalentSearchJobBalanceQueryVariables
  >(GetTalentSearchJobBalanceDocument, options);
}
export function useGetTalentSearchJobBalanceSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetTalentSearchJobBalanceQuery,
        GetTalentSearchJobBalanceQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetTalentSearchJobBalanceQuery,
    GetTalentSearchJobBalanceQueryVariables
  >(GetTalentSearchJobBalanceDocument, options);
}
export type GetTalentSearchJobBalanceQueryHookResult = ReturnType<
  typeof useGetTalentSearchJobBalanceQuery
>;
export type GetTalentSearchJobBalanceLazyQueryHookResult = ReturnType<
  typeof useGetTalentSearchJobBalanceLazyQuery
>;
export type GetTalentSearchJobBalanceSuspenseQueryHookResult = ReturnType<
  typeof useGetTalentSearchJobBalanceSuspenseQuery
>;
export type GetTalentSearchJobBalanceQueryResult = Apollo.QueryResult<
  GetTalentSearchJobBalanceQuery,
  GetTalentSearchJobBalanceQueryVariables
>;

// Default exports can be ignored for this file. sku templates provide default exports as standard here
/* eslint-disable import/no-default-export */

import 'braid-design-system/reset';
import { HirerAuthProvider } from '@seek/hirer-auth-react';
import { StrictMode } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import { getHirerAuthProviderProps } from 'src/config/getHirerAuthProviderProps';
import useAppConfig from 'src/hooks/useAppConfig';

import { ACLProvider } from '../../hooks/useACL';
import { DataServiceProvider } from '../../hooks/useDataService';
import { DatadogRumProvider } from '../../hooks/useDatadogRum';
import { ExperimentProvider, experiments } from '../../hooks/useExperiment';
import { HotjarProvider, polls } from '../../hooks/useHotjar';
import { MediaQueryProvider } from '../../hooks/useMediaQuery';
import { MetricsProvider } from '../../hooks/useMetrics';
import { NotificationsProvider } from '../../hooks/useNotifications';
import { ThemedBraidProvider } from '../../hooks/useStyleGuide';
import dataPlatform from '../../metrics/dataPlatform';
import tealium from '../../metrics/tealium';
import type { RouteDefinition } from '../../types';

import { AppView } from './components/AppView/AppView';
import { HirerGraphQLProvider } from './components/HirerGraphQLProvider/HirerGraphQLProvider';
import TranslationProvider from './components/TranslationProvider/TranslationProvider';

interface Props {
  routes: {
    unrestricted: RouteDefinition[];
    restricted: RouteDefinition[];
    recommendedCandidates: RouteDefinition[];
  };
}

function App({ routes }: Props) {
  const config = useAppConfig();

  return (
    <HelmetProvider>
      <TranslationProvider>
        <DatadogRumProvider>
          <ACLProvider>
            <MetricsProvider providers={[tealium, dataPlatform]}>
              <HirerAuthProvider
                {...getHirerAuthProviderProps({
                  environment: config.ENVIRONMENT,
                })}
              >
                <HirerGraphQLProvider>
                  <ThemedBraidProvider>
                    <NotificationsProvider>
                      <ExperimentProvider experiments={experiments}>
                        <DataServiceProvider>
                          <MediaQueryProvider>
                            <HotjarProvider polls={polls}>
                              <StrictMode>
                                <AppView routes={routes} />
                              </StrictMode>
                            </HotjarProvider>
                          </MediaQueryProvider>
                        </DataServiceProvider>
                      </ExperimentProvider>
                    </NotificationsProvider>
                  </ThemedBraidProvider>
                </HirerGraphQLProvider>
              </HirerAuthProvider>
            </MetricsProvider>
          </ACLProvider>
        </DatadogRumProvider>
      </TranslationProvider>
    </HelmetProvider>
  );
}

export default App;

import type { GraphQLFormattedError } from 'graphql';

import type { ContextType } from 'src/hooks/useDatadogRum';

const mapRequestErrorLogger =
  (logger: ContextType) =>
  (error: unknown, operationName?: string, metadata?: any) => {
    logger.logError(error instanceof Error ? error.message : 'Unknown error', {
      ...metadata,
      operationName,
      activity: operationName,
      error,
    });
  };

const mapGraphQLErrorLogger =
  (logger: ContextType) =>
  (error: GraphQLFormattedError, operationName?: string, metadata?: any) => {
    logger.logError(error.message, {
      ...metadata,
      operationName,
      activity: operationName,
      error,
    });
  };

export { mapRequestErrorLogger, mapGraphQLErrorLogger };

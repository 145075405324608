import type { ConnectionType } from 'src/hooks/useConnections/types';
import { type Message, MessageTemplateKey, PoolActionTypes } from 'src/types';

import {
  createConnectionFailureTemplate,
  createConnectionSuccessTemplate,
  createThrottlingLimitTemplate,
} from './templates';
import { getEnumKeyByStringValue } from './utils';

type Option =
  | {
      key: MessageTemplateKey.CONNECTION_FAILURE;
      params: {
        firstName: string;
        lastName: string;
        connectionType: ConnectionType;
        atsName: string;
      };
    }
  | {
      key: MessageTemplateKey.CONNECTION_SUCCESS;
      params: {
        firstName: string;
        lastName: string;
        connectionType: ConnectionType;
        atsName: string;
      };
    }
  | {
      key: MessageTemplateKey.EXCEEDED_THROTTLING_LIMIT;
      params: { connectionType: ConnectionType };
    }
  | {
      key: MessageTemplateKey.GENERIC_FAILURE;
      params: { action: string; dedupingKey?: string };
    }
  | {
      key: MessageTemplateKey.GENERIC_FAILURE_WITH_RETRY;
      params: {
        action: string;
        dedupingKey?: string;
        retryLabel: string;
        onRetry: () => void;
      };
    }
  | {
      key: MessageTemplateKey.POOLING_FAILURE;
      params: { poolAction: PoolActionTypes; plural?: boolean };
    };

export const getCreateMessageFromTemplate =
  (translation: (key: string, params?: any) => string) =>
  (option: Option): Message => {
    let message;
    let description;
    let action;
    let dedupingKey;
    let retryLabel;
    let onRetry;

    switch (option.key) {
      case MessageTemplateKey.CONNECTION_FAILURE:
        ({ message, description } = createConnectionFailureTemplate(
          translation,
          option.params,
        ));

        return {
          key: option.key.toString(),
          message: translation('CONNECTION_FAILURE_MESSAGE', {
            action: message,
          }),
          description: translation('CONNECTION_FAILURE_DESCRIPTION', {
            description,
          }),
          tone: 'critical',
        };

      case MessageTemplateKey.CONNECTION_SUCCESS:
        ({ message } = createConnectionSuccessTemplate(
          translation,
          option.params.connectionType,
        ));

        return {
          key: option.key.toString(),
          message: translation('CONNECTION_SUCCESS_MESSAGE', {
            action: message,
          }),
          tone: 'positive',
        };

      case MessageTemplateKey.EXCEEDED_THROTTLING_LIMIT:
        ({ message } = createThrottlingLimitTemplate(
          translation,
          option.params,
        ));

        return {
          key: option.key.toString(),
          message: translation('EXCEEDED_THROTTLING_LIMIT_MESSAGE', {
            action: message,
          }),
          tone: 'critical',
        };

      case MessageTemplateKey.GENERIC_FAILURE:
        ({ action, dedupingKey } = option.params);

        return {
          ...(dedupingKey && { key: dedupingKey }),
          message: translation('GENERIC_FAILURE_MESSAGE', { action }),
          tone: 'critical',
        };

      case MessageTemplateKey.GENERIC_FAILURE_WITH_RETRY:
        ({ action, dedupingKey, retryLabel, onRetry } = option.params);

        return {
          ...(dedupingKey && { key: dedupingKey }),
          message: translation('GENERIC_FAILURE_WITH_RETRY_MESSAGE', {
            action,
          }),
          tone: 'critical',
          action: {
            label: retryLabel,
            onClick: onRetry,
          },
        };

      case MessageTemplateKey.POOLING_FAILURE:
        const { poolAction, plural = false } = option.params;

        return {
          key: option.key.toString(),
          message: translation('POOLING_FAILURE_MESSAGE', {
            poolAction: translation(
              getEnumKeyByStringValue(PoolActionTypes, poolAction),
            ),
            poolText: plural ? translation('POOLS') : translation('POOL'),
          }),
          tone: 'critical',
        };

      default:
        throw new Error('Message key not mapped');
    }
  };

import { findVerifiedName } from 'src/common/UI/Profile/FullProfileLayout/components/ProfileDetails/helpers';
import type { ProfileV2 } from 'src/types';
export const getSearchResultTrackingFields = (
  profiles: ProfileV2[],
): {
  searchResultsPageSize: number;
  searchResultsVerifiedCredentialsCount: number;
  searchResultsVerifiedIdCount: number;
  searchResultsVerifiedRTWCount: number;
  searchResultsVerifiedLicenceCount: number;
  searchResultsVerifiedEducationCount: number;
  searchResultsNationalityCount: number;
} => {
  const searchResultsPageSize = profiles.length;
  const searchResultsVerifiedCredentialsCount: number = profiles.filter(
    (profile) => profile.hasVerifiedCredentials,
  ).length;

  const searchResultsVerifiedIdCount = profiles.reduce(
    (acc, item) =>
      findVerifiedName(item.digitalIdentity?.verifiedFields) ? acc + 1 : acc,
    0,
  );

  const searchResultsVerifiedRTWCount: number = profiles.filter(
    (profile) => profile.rightToWork?.isVerified,
  ).length;

  const searchResultsVerifiedLicenceCount: number = profiles.filter(
    (profile) => {
      const verifiedLicencesCount =
        profile.licences?.filter((licence) => licence.isVerified).length ?? 0;
      return verifiedLicencesCount > 0;
    },
  ).length;

  const searchResultsVerifiedEducationCount: number = profiles.filter(
    (profile) => {
      const verifiedEducationCount =
        profile.profileEducation?.filter((education) => education.isVerified)
          .length ?? 0;
      return verifiedEducationCount > 0;
    },
  ).length;

  const searchResultsNationalityCount: number = profiles.filter(
    (profile) => profile.nationalities.length > 0,
  ).length;

  return {
    searchResultsPageSize,
    searchResultsVerifiedCredentialsCount,
    searchResultsVerifiedIdCount,
    searchResultsVerifiedRTWCount,
    searchResultsVerifiedLicenceCount,
    searchResultsVerifiedEducationCount,
    searchResultsNationalityCount,
    // Please add new tealium tracking fields here
  };
};

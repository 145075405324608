import { Logo } from '@seek/logo';
import { Box, Divider, Link } from 'braid-design-system';
import { useSelector } from 'react-redux';

import useAppConfig from 'src/hooks/useAppConfig';

import useMediaQuery from '../../../../hooks/useMediaQuery';
import { selectors as routerSelectors } from '../../../../store/router';
import { selectors as userSelectors } from '../../../../store/user';
import { Tags } from '../../../../types';

import { useGetSwitchAccountsHrefQuery } from './components/AccountSwitcher/GetSwitchAccountsHref.generated';
import Menu from './components/Menu/Menu';
import MenuDrawer from './components/MenuDrawer/MenuDrawer';

import * as styles from './Header.css';

function Header() {
  const { ROUTER_BASE_NAME } = useAppConfig();

  const { isMobile } = useMediaQuery();
  const isLoggedIn = useSelector(userSelectors.getInitialDataStateSelector);
  const isValidRoute = useSelector(routerSelectors.isValidRouteSelector);

  // fetch the switch accounts href when loading the header to avoid a flash of the switch accounts button
  useGetSwitchAccountsHrefQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (!isValidRoute) {
    return null;
  }

  return (
    <>
      <Box className={styles.headerHeight} />
      <Box
        component="header"
        background="body"
        position="fixed"
        zIndex="sticky"
        width="full"
        data={{ cy: 'talent-search-header' }}
      >
        <Box
          data={{ cy: Tags.Data.Logo }}
          className={[styles.headerHeight, styles.gap]}
          paddingX="medium"
          display="flex"
          justifyContent={isMobile ? 'spaceBetween' : undefined}
          alignItems="center"
        >
          <Box className={styles.logoContainer} data={{ testid: 'logo' }}>
            <Link href={`${window.location.origin}${ROUTER_BASE_NAME}`}>
              <Logo brand="seek" size="fill" />
            </Link>
          </Box>

          {isLoggedIn && (isMobile ? <MenuDrawer /> : <Menu />)}
        </Box>
        <Divider />
      </Box>
    </>
  );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default Header;

import type { ConnectionType } from 'src/hooks/useConnections/types';

interface ConnectionFailureParams {
  firstName: string;
  lastName: string;
  connectionType: ConnectionType;
  atsName: string;
}

export function createConnectionFailureTemplate(
  translation: (key: string, params?: any) => string,
  { connectionType, firstName, lastName, atsName }: ConnectionFailureParams,
) {
  // TODO make me a map.... one day. Udanie has banned defaults.
  switch (connectionType) {
    case 'sendJob':
      return {
        message: translation('SEND_JOB'),
        description: translation('SEND_JOB_TO_SOMEONE', {
          firstName,
          lastName,
        }),
      };

    case 'sendMessage':
      return {
        message: translation('SEND_MESSAGE'),
        description: translation('SEND_MESSAGE_TO_SOMEONE', {
          firstName,
          lastName,
        }),
      };

    case 'accessCv':
      return {
        message: translation('ACCESS_CV'),
        description: translation('ACCESS_CV_OF_SOMEONE', {
          firstName,
          lastName,
        }),
      };

    case 'accessProfile':
      return {
        message: translation('ACCESS_PROFILE'),
        description: translation('ACCESS_PROFILE_OF_SOMEONE', {
          firstName,
          lastName,
        }),
      };

    case 'accessProfileAndCv':
      return {
        message: translation('ACCESS_PROFILE_AND_CV'),
        description: translation('ACCESS_PROFILE_AND_CV_OF_SOMEONE', {
          firstName,
          lastName,
        }),
      };

    case 'exportProfile':
    case 'exportProfileWithAtsJob':
      return {
        message: translation('ADD_PROFILE'),
        description: translation('ADD_PROFILE_TO_ATS', {
          firstName,
          lastName,
          atsName,
        }),
      };

    default:
      return {
        message: translation('CONNECT'),
        description: translation('CONNECT_TO_SOMEONE', {
          firstName,
          lastName,
        }),
      };
  }
}

export function createConnectionSuccessTemplate(
  translation: (key: string) => string,
  connectionType: ConnectionType,
) {
  switch (connectionType) {
    case 'sendJob':
      return {
        message: translation('JOB_SENT'),
      };

    case 'sendMessage':
      return {
        message: translation('MESSAGE_SENT'),
      };

    case 'accessCv':
      return {
        message: translation('CV_ACCESSED'),
      };

    case 'accessProfile':
      return {
        message: translation('PROFILE_ACCESSED'),
      };

    case 'accessProfileAndCv':
      return {
        message: translation('PROFILE_AND_CV_ACCESSED'),
      };

    case 'exportProfile':
      return {
        message: translation('PROFILE_ADDED'),
      };

    default:
      return {
        message: translation('CONNECTION_SUCCESSFUL'),
      };
  }
}

export function createThrottlingLimitTemplate(
  translation: (key: string, params?: any) => string,
  {
    connectionType,
  }: {
    connectionType: ConnectionType;
  },
) {
  switch (connectionType) {
    case 'sendJob':
      return { message: translation('SEND_JOB') };

    case 'sendMessage':
      return { message: translation('SEND_MESSAGE') };

    case 'accessCv':
      return { message: translation('ACCESS_CV') };

    case 'accessProfile':
      return { message: translation('ACCESS_PROFILE') };

    case 'accessProfileAndCv':
      return { message: translation('ACCESS_PROFILE') };

    case 'exportProfile':
      return { message: translation('ADD_PROFILE') };

    default:
      return { message: translation('CONNECTION') };
  }
}

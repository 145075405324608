import * as Apollo from '@apollo/client';
import type { DocumentNode } from 'graphql';

import type * as Types from '../../../types/generated';

const defaultOptions = {} as const;
export type CreditsBalanceFieldsFragment = {
  __typename: 'TalentSearchJobCreditsBalance';
  currentBalance: number;
  initialBalance: number;
};

export type InvitationsBalanceFieldsFragment = {
  __typename: 'TalentSearchCandidateJobInvitationsBalance';
  currentBalance: number;
  initialBalance: number;
};

export type JobBalanceFields_ResponseError_Fragment = {
  __typename: 'ResponseError';
  error: Types.ResponseErrorReason;
};

export type JobBalanceFields_TalentSearchCandidateJobInvitationsBalance_Fragment =
  {
    __typename: 'TalentSearchCandidateJobInvitationsBalance';
    currentBalance: number;
    initialBalance: number;
  };

export type JobBalanceFields_TalentSearchJobCreditsBalance_Fragment = {
  __typename: 'TalentSearchJobCreditsBalance';
  currentBalance: number;
  initialBalance: number;
};

export type JobBalanceFields_TalentSearchUnavailableJobBalance_Fragment = {
  __typename: 'TalentSearchUnavailableJobBalance';
};

export type JobBalanceFieldsFragment =
  | JobBalanceFields_ResponseError_Fragment
  | JobBalanceFields_TalentSearchCandidateJobInvitationsBalance_Fragment
  | JobBalanceFields_TalentSearchJobCreditsBalance_Fragment
  | JobBalanceFields_TalentSearchUnavailableJobBalance_Fragment;

export type GetTalentSearchJobQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  language?: Types.InputMaybe<Types.Scalars['LanguageCodeIso']['input']>;
}>;

export type GetTalentSearchJobQuery = {
  talentSearchJob?: {
    id: string;
    title: string;
    expiryDate: string;
    reference?: string | null;
    isUpgradable?: boolean | null;
    balanceV2:
      | { __typename: 'ResponseError'; error: Types.ResponseErrorReason }
      | {
          __typename: 'TalentSearchCandidateJobInvitationsBalance';
          currentBalance: number;
          initialBalance: number;
        }
      | {
          __typename: 'TalentSearchJobCreditsBalance';
          currentBalance: number;
          initialBalance: number;
        }
      | { __typename: 'TalentSearchUnavailableJobBalance' };
    location: { countryCode?: string | null; description?: string | null };
    salary: { frequency: Types.SalaryFrequency };
  } | null;
};

export const CreditsBalanceFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditsBalanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TalentSearchJobCreditsBalance' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialBalance' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const InvitationsBalanceFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvitationsBalanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'TalentSearchCandidateJobInvitationsBalance',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialBalance' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const JobBalanceFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobBalanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TalentSearchJobBalance' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TalentSearchJobCreditsBalance' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreditsBalanceFields' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'TalentSearchCandidateJobInvitationsBalance',
              },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InvitationsBalanceFields' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'TalentSearchUnavailableJobBalance',
              },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResponseError' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditsBalanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TalentSearchJobCreditsBalance' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialBalance' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvitationsBalanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'TalentSearchCandidateJobInvitationsBalance',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialBalance' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const GetTalentSearchJobDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTalentSearchJob' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'language' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'LanguageCodeIso' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'talentSearchJob' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'jobId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expiryDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'balanceV2' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'JobBalanceFields' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'language' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'language' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countryCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salary' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'frequency' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isUpgradable' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreditsBalanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TalentSearchJobCreditsBalance' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialBalance' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'InvitationsBalanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'TalentSearchCandidateJobInvitationsBalance',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBalance' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initialBalance' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobBalanceFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TalentSearchJobBalance' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TalentSearchJobCreditsBalance' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreditsBalanceFields' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'TalentSearchCandidateJobInvitationsBalance',
              },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'InvitationsBalanceFields' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'TalentSearchUnavailableJobBalance',
              },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ResponseError' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'error' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetTalentSearchJobQuery__
 *
 * To run a query within a React component, call `useGetTalentSearchJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTalentSearchJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTalentSearchJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetTalentSearchJobQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTalentSearchJobQuery,
    GetTalentSearchJobQueryVariables
  > &
    (
      | { variables: GetTalentSearchJobQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTalentSearchJobQuery,
    GetTalentSearchJobQueryVariables
  >(GetTalentSearchJobDocument, options);
}
export function useGetTalentSearchJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTalentSearchJobQuery,
    GetTalentSearchJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTalentSearchJobQuery,
    GetTalentSearchJobQueryVariables
  >(GetTalentSearchJobDocument, options);
}
export function useGetTalentSearchJobSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetTalentSearchJobQuery,
        GetTalentSearchJobQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetTalentSearchJobQuery,
    GetTalentSearchJobQueryVariables
  >(GetTalentSearchJobDocument, options);
}
export type GetTalentSearchJobQueryHookResult = ReturnType<
  typeof useGetTalentSearchJobQuery
>;
export type GetTalentSearchJobLazyQueryHookResult = ReturnType<
  typeof useGetTalentSearchJobLazyQuery
>;
export type GetTalentSearchJobSuspenseQueryHookResult = ReturnType<
  typeof useGetTalentSearchJobSuspenseQuery
>;
export type GetTalentSearchJobQueryResult = Apollo.QueryResult<
  GetTalentSearchJobQuery,
  GetTalentSearchJobQueryVariables
>;

import { Box, Divider } from 'braid-design-system';
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useACL } from '../../../../../../hooks/useACL/useACL';
import useWindow from '../../../../../../hooks/useWindow';
import { selectors as userSelectors } from '../../../../../../store/user';
import { ACL, KeyMap } from '../../../../../../types';
import DocumentKeyEventHandler from '../../../../../Handlers/DocumentKeyEventHandler';
import { AccountSwitcher } from '../AccountSwitcher/AccountSwitcher';
import ActiveAccount from '../ActiveAccount/ActiveAccount';
import MenuLanguageSwitcher from '../MenuDrawer/MenuLanguageSwitcher/MenuLanguageSwitcher';
import SavedSearchList from '../SavedSearchList/SavedSearchList';
import { SignOut } from '../SignOut/SignOut';
import TrialWarning from '../TrialWarning/TrialWarning';
import User from '../User/User';

import LanguageSwitcher from './LanguageSwitcher/LanguageSwitcher';
import PageLinks from './PageLinks/PageLinks';

import * as styles from './Menu.css';

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const userMenuRef = useRef<any>(null);

  const { scrollToTop } = useWindow();
  const { hasAccess } = useACL();

  const userFirstName = useSelector(userSelectors.getUserFirstNameSelector);
  const advertiserName = useSelector(userSelectors.getAdvertiserNameSelector);
  const accountNumber = useSelector(
    userSelectors.getAdvertiserAccountNumberSelector,
  );
  const isConnectedTalentSearchUser = useSelector(
    userSelectors.isConnectedTalentSearchUserSelector,
  );
  const premiumContract = useSelector(userSelectors.getPremiumContract);

  useEffect(() => {
    const closeMenu = (e: any) => {
      if (isOpen && !userMenuRef.current?.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', closeMenu);

    return () => {
      document.removeEventListener('mousedown', closeMenu);
    };
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = () => {
    setIsOpen(false);
    scrollToTop();
  };

  const isOnTrial = premiumContract && premiumContract.type === 'trial';

  return (
    <>
      <PageLinks onSelect={handleSelect} />

      <LanguageSwitcher />

      {/* Todo - replace boxes with Stack + dividers prop */}
      <Box display="flex" ref={userMenuRef}>
        <User
          userFirstName={userFirstName}
          advertiserName={advertiserName}
          isConnectedTalentSearchUser={isConnectedTalentSearchUser}
          isOnTrial={isOnTrial}
          isToggled={isOpen}
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation();
            toggleMenu();
          }}
        />

        {isOpen && (
          <DocumentKeyEventHandler
            keyMap={{
              [KeyMap.Escape]: toggleMenu,
            }}
          >
            <Box
              background="surface"
              className={styles.menu}
              borderRadius="large"
            >
              <Box padding="medium">
                <ActiveAccount
                  advertiserName={advertiserName}
                  userFirstName={userFirstName}
                  accountNumber={accountNumber}
                />
              </Box>
              <Box paddingX="medium">
                <Divider />
              </Box>
              {isOnTrial && premiumContract && (
                <TrialWarning premiumContract={premiumContract} />
              )}
              {hasAccess(ACL.SEARCH_FEATURE.SAVED_SEARCHES) && (
                <SavedSearchList onSelect={handleSelect} hasScroll={true} />
              )}
              <Box paddingX="medium" paddingBottom="medium">
                <Divider />
              </Box>
              <MenuLanguageSwitcher />
              <AccountSwitcher />
              <SignOut />
            </Box>
          </DocumentKeyEventHandler>
        )}
      </Box>
    </>
  );
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default Menu;

import { useGetTalentSearchContractBalanceQuery } from './gql/GetContractBalance.generated';
import { useGetTalentSearchJobBalanceQuery } from './gql/GetJobBalance.generated';

interface Props {
  jobId: string | number | null;
}

export const useBalances = ({ jobId: inputJobId }: Props) => {
  // recommended candidates passes the jobId as a string. Lets handle the conversion here.
  const jobId =
    typeof inputJobId === 'string' ? parseInt(inputJobId, 10) : inputJobId;

  const {
    loading: contractBalanceLoading,
    data: contractBalanceData,
    error: contractBalanceError,
  } = useGetTalentSearchContractBalanceQuery({
    variables: {
      jobId,
    },
  });
  const {
    loading: jobBalanceLoading,
    data: jobBalanceData,
    error: jobBalanceError,
  } = useGetTalentSearchJobBalanceQuery({
    variables: {
      jobId: jobId!, // this will be skipped if jobId is not a number
    },
    skip: typeof jobId !== 'number',
  });

  const contractBalance =
    contractBalanceData?.talentSearchUserContext.contractBalance.__typename ===
    'TalentSearchContractBalance'
      ? contractBalanceData.talentSearchUserContext.contractBalance
      : null;

  const jobBalance =
    jobBalanceData?.talentSearchJob?.balanceV2.__typename ===
      'TalentSearchCandidateJobInvitationsBalance' ||
    jobBalanceData?.talentSearchJob?.balanceV2.__typename ===
      'TalentSearchJobCreditsBalance'
      ? {
          balance: jobBalanceData.talentSearchJob.balanceV2,
          expiryDate: jobBalanceData.talentSearchJob.expiryDate,
        }
      : null;

  return {
    loading: contractBalanceLoading && jobBalanceLoading,
    hasError: Boolean(contractBalanceError) || Boolean(jobBalanceError),
    contractBalance,
    jobBalance,
  };
};

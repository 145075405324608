import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { RouteLoader } from 'src/common/App/components/RouteLoader/RouteLoader';

import useRouter from '../../../../../hooks/useRouter';
import locationService from '../../../../../services/location';
import queryStringService, {
  parsers,
} from '../../../../../services/location/queryStringService';
import { actionCreators as settingsActionCreators } from '../../../../../store/settings';

/*
 * Ensures that if a ?advertiserId=xx is provided when attempting to
 * render a route, we should try to resolve toggling to this account before
 * the route is rendered.
 */
function withAccountToggle<P extends JSX.IntrinsicAttributes>(
  WrappedComponent: React.ComponentType<P>,
): React.ComponentType<P> {
  return (props: P) => {
    const dispatch = useDispatch();

    const {
      location: { search },
      navigateToError,
    } = useRouter();

    //  If the URL does NOT contain ?advertiserId=xx
    //  we can short circuit the account toggle
    if (!search.includes('advertiserId')) {
      return <WrappedComponent {...(props as P)} />;
    }

    const [{ advertiserId }, nextSearch] = queryStringService.extractParams(
      search,
      ['advertiserId'],
      {
        advertiserId: { type: parsers.number },
      },
    );

    //  Toggling required if an advertiserId exists in the URL
    //  and this value is different to the current advertiserId
    useEffect(() => {
      (async () => {
        const pathname = window.location.pathname;
        const pathWithNoAdvertiserId = nextSearch
          ? `${pathname}?${nextSearch}`
          : pathname;

        if (!advertiserId) {
          locationService.redirectToPage(pathWithNoAdvertiserId);
          return;
        }

        /**
         * Persists the selected advertiserId so that we can
         * use this value on the next log in as a preferred account
         */
        dispatch(
          settingsActionCreators.updatePreferredAdvertiserId(advertiserId),
        );

        //  Reloads the page and retains active search criteria.
        locationService.redirectToPage(pathWithNoAdvertiserId);
      })();
    }, [nextSearch, advertiserId, navigateToError, dispatch]);

    return <RouteLoader />;
  };
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default withAccountToggle;

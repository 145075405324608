import { gql } from '@apollo/client';
import { print } from 'graphql';

import type { GraphResponse, TalentSearchJobsV2 } from '../../types';

export const GET_FIRST_ACTIVE_JOB_ID = gql`
  query GetFirstActiveTalentSearchJob {
    talentSearchJobsV2(input: { maxJobs: 1 }) {
      jobs {
        id
      }
    }
  }
`;

/**
 * @deprecated This approach is not ideal for STS, this needs a rethink
 */
async function getFirstActiveJobId({
  hirerGraphUrl,
  advertiserId,
  token,
}: {
  hirerGraphUrl: string;
  advertiserId: number;
  token: string;
}) {
  try {
    const fetchResponse = await fetch(hirerGraphUrl, {
      method: 'POST',
      headers: {
        'x-seek-site': 'talent-search-ui',
        'Content-Type': 'application/json',
        authorization: token,
        advertiserId: `${advertiserId}`,
      },
      body: JSON.stringify({ query: print(GET_FIRST_ACTIVE_JOB_ID) }),
    });

    if (fetchResponse.ok) {
      const fetchResponseJson = (await fetchResponse.json()) as GraphResponse<{
        talentSearchJobsV2: TalentSearchJobsV2;
      }>;
      const [firstActiveJob] =
        fetchResponseJson.data?.talentSearchJobsV2?.jobs || [];
      return firstActiveJob?.id || null;
    }

    // 4xx, 5xx errors for example
    Promise.reject(fetchResponse);
  } catch (err: any) {
    // fetch throws on network errors, not eg. 4xx or 5xx
    // eslint-disable-next-line no-console
    console.error('GraphQL Error:', err.message);
    return null;
  }
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default getFirstActiveJobId;

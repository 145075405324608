import { useTranslations } from '@vocab/react';
import { Box, Text, Badge, IconChevron } from 'braid-design-system';

import { Tags } from '../../../../../../types';

import translations from './.vocab';

export interface UserProps {
  userFirstName: string | null;
  advertiserName: string | null;
  isConnectedTalentSearchUser: boolean;
  isOnTrial: boolean | null;
  isToggled: boolean;
  onClick: (e: React.MouseEvent) => void;
}

function User({
  userFirstName,
  advertiserName,
  isConnectedTalentSearchUser,
  isOnTrial,
  isToggled,
  onClick,
}: UserProps) {
  const { t } = useTranslations(translations);

  const Badges = () => {
    if (isOnTrial) {
      return <Badge tone="promote">{t('TRIAL')}</Badge>;
    }

    if (isConnectedTalentSearchUser) {
      return <Badge tone="promote">TSC</Badge>;
    }

    return null;
  };

  return (
    <Box
      component="button"
      position="relative"
      onClick={onClick}
      cursor="pointer"
      data={{ cy: Tags.Data.AccountToggle }}
    >
      <Text size="small">
        {userFirstName || advertiserName || 'Menu'} <Badges />
        {'  '}
        <IconChevron direction={isToggled ? 'up' : 'down'} />
      </Text>
    </Box>
  );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default User;

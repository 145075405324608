import { Fragment } from 'react';
import { createPortal } from 'react-dom';
import Transition from 'react-transition-group/Transition';

import Context from './Context';
import Content from './components/Content';
import Overlay from './components/Overlay';
import getPortalRoot from './getPortalRoot';
import { type Theme, type OpenFrom, Timeout } from './types';

interface Props {
  isOpen: boolean;
  children: React.ReactNode;
  appearTimeoutMs?: number;
  exitTimeoutMs?: number;
  portalId?: string;
  openFrom?: OpenFrom;
  disableOverlay?: boolean;
  disableScrollLock?: boolean;
  theme?: Partial<Theme>;
  onClose: () => void;
}

/**
 * @deprecated Use Braid `<Drawer />` instead
 */
function Drawer({
  isOpen,
  children,
  appearTimeoutMs = Timeout.AppearTimeoutMS,
  exitTimeoutMs = Timeout.ExitTimeoutMS,
  portalId = 'modal-root',
  openFrom = 'right',
  theme = {},
  disableOverlay = false,
  onClose,
}: Props) {
  return (
    <Context.Provider value={{ theme }}>
      {createPortal(
        <Transition
          in={isOpen}
          timeout={{
            appear: appearTimeoutMs,
            exit: exitTimeoutMs,
          }}
        >
          {(state: string) =>
            state !== 'exited' && (
              <Fragment>
                <Content isOpen={isOpen} openFrom={openFrom} state={state}>
                  {children}
                </Content>

                {!disableOverlay && <Overlay state={state} onClose={onClose} />}
              </Fragment>
            )
          }
        </Transition>,
        getPortalRoot(portalId),
      )}
    </Context.Provider>
  );
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default Drawer;

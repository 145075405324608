import type { Action } from '../../types';

import * as actions from './actions';

export interface State {
  searchId: string;
  similarCandidatesSearchIdInProfile: string;
  activeSimilarCandidatesId?: string;
  profiles: any;
}

const initialState: State = {
  searchId: '',
  similarCandidatesSearchIdInProfile: '',
  profiles: {},
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case actions.UPDATE_SEARCH_ID: {
      const { searchId } = action.value;
      return {
        ...state,
        searchId,
      };
    }

    case actions.UPDATE_SIMILAR_CANDIDATES_SEARCH_ID_IN_PROFILE: {
      const { similarCandidatesSearchIdInProfile } = action.value;
      return {
        ...state,
        similarCandidatesSearchIdInProfile,
      };
    }

    case actions.UPDATE_ACTIVE_SIMILAR_CANDIDATES_ID: {
      const { activeSimilarCandidatesId } = action.value;
      return {
        ...state,
        activeSimilarCandidatesId,
      };
    }

    default:
      return state;
  }
};

import { useHirerAuth } from '@seek/hirer-auth-react';
import { configureIntercom } from '@seek/hirer-intercom-adapter';
import { getLanguageFromLocale } from '@seek/melways-sites';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useAppConfig from '../hooks/useAppConfig';
import { selectors as userSelectors } from '../store/user';

export const IntercomConfiguration = () => {
  const { getIdentity } = useHirerAuth();
  const { SITE: site, LANGUAGE: language } = useAppConfig();
  const userFirstName = useSelector(userSelectors.getUserFirstNameSelector);
  const userLastName = useSelector(userSelectors.getUserLastNameSelector);
  const email = useSelector(userSelectors.getUserEmailSelector);

  const fullName = `${userFirstName ?? ''} ${userLastName ?? ''}`.trim();

  useEffect(() => {
    (async () => {
      const identity = await getIdentity();
      const isImpersonating = Boolean(identity?.act);

      if (identity && email && fullName && !isImpersonating) {
        configureIntercom({
          email,
          name: fullName,
          site,
          language: getLanguageFromLocale(language), // This strips the en-PSEUDO
        });
      }
    })();
  }, [email, fullName, site, language, getIdentity]);

  return null;
};
